.product-hero{
	color: white;
	background-color: $col-darkBrown;

	text-align: center;

	width: 100%;
	overflow: hidden;

	&.is-fixed{
		position: fixed;
	}
}

	.product-hero__background{
		@include productImage;
	}

	.product-hero__buyWrapper {

		left: 0;
		right: 0;

		z-index: 100;

		@include respondTo("tb") {
			
			position: absolute;
			bottom: -1px;

		}
	}

	.product-hero__content{
		padding: 0 20px;
		margin: 0 auto;
		margin-bottom: 3rem;
		margin-top: 2rem;
		font-size: _fs(20);

		width: 100%;

		@include respondTo("tb") {

			margin-bottom: 4rem;

		}

		.o-socials {
			display: none;
			margin-top: 1rem;

			svg > path {
				
				fill: white;

			}

			@include respondTo("tb") {
				
				display: block;

			}

		}
	}

	.product-hero__container{
		padding-top: 2rem;
		padding-left: 2rem;
		padding-right: 2rem;

		@include respondTo("md") {
			
			padding: 0px;

		}

		@include respondTo("tb") {
			padding-bottom: 2rem;
		}
	}

	.product-hero__heading *{
		color: white;
	}

	.product-hero__info{
		margin: 0 auto;
		height: 70px;

		img{

			height: 100%;
			display: inline-block;
			margin: 0 5px;
		}
	}

	.product-wrapper {
		
		width: 100% !important;

	}

@media screen and ( min-width: 500px ){

	.product-hero__content{
		text-align: center;
	}

}

@media screen and ( min-width: 1025px ){

	.product-hero{

		display: flex;
		justify-content: left;
		align-items: center;

		position: relative;

		text-align: center;

		margin: 0 auto;

	}

		.product-hero__container{

			left: 0px;
			top: 50%;
			transform: translateY(-50%);
			width: 40%;

			position: absolute;
			z-index: 2;

			padding: 0 100px;
		}

		.product-hero__background {

			width: 100%;

			&:before,
			&:after{
				content: '';
				position: absolute;

				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				background-color: rgba( $col-darkBrown, .05);
			}

			&:after{
				left: inherit;
				right: 0;
				background: linear-gradient(to left, rgba( $col-darkBrown ,1) 0%,rgba( $col-darkBrown,0) 100%)

			}

		}

		.product-hero__content{
			text-align: center;
		}

		.product-hero__background{
			
			margin-bottom: 0;

		}

		.product-hero__buyWrapper {

			width: 375px;		
			position: absolute;
			right: 0;
			bottom: -1px;

			left: auto;

			z-index: 2;

		}

		.product-hero__info{
			position: absolute;
			bottom: -1px;

			width: 40%;		
		}

}
