$dotSize: 10px;
$miniDotSize: 5px;
$barSize: 30px;

$startingAngle: 45deg;
$finalAngle: 45deg * 7;

$enterTime: 1.5s;
$exitTime: $enterTime / 2;

$bulletColor: $col-lightBrown;

@mixin bulletColor( $color: $col-lightBrown ){

  background-color: $color !important;

  i{
    &,
    &:after,
    &:before{
      background-color: $color !important;
    }
  }

}

.o-slider {
    display: block;
    height: 100%;
    width:100%;

    &--cocktailSliderHome {

      .swiper-pagination {

        display: none;

      }

    }
}

.js-slider--products {

  margin: 35px 0;

}

  .swiper-slide {

    // cursor: pointer;
    text-align: center;

  }

  .swiper-slide__image {

    width: 100%;

  }

  .swiper-pagination {

    position: static;
    bottom: 0px;

  }

  .swiper-container{
    width: 100%;
    height: 100%;
  }

.swiper-pagination-bullets{
  transform-origin: 0 0;
  margin: 0 auto;
  display: block;
  padding: 10px 0;
  text-align: center;
}

.slider-pagination-bullet {
  width: $dotSize;
  height: $dotSize;

  border-radius: $dotSize;

  margin: 10px;

  display: inline-block;


  opacity: 1 !important;

  cursor: pointer;

  // perche va messo due volte?
  //margin:0 7.5px !important;

  position: relative;

  @include bulletColor();

	i{
  	width: 0;
    height: 2px;

    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50% ,-50%) rotate($startingAngle) scale(0);

    animation: exit $exitTime ease 0s;

    &:before,
    &:after{
      content: '';
      position: absolute;

      width: 6px;
      height: 6px;

      border-radius: 6px;


      top: 50%;
      left: 0;

      transform: translate(0,-50%);

    }

    &:after{
      right: 0;
      left: auto;
    }
	}

}

.slider-pagination-bullet.active{

  i{

    width: 0;
    height: 2px;

    position: absolute;

    background-color: $bulletColor;

    top: 50%;
    left: 50%;

    transform: translateX(-50% , -50%) rotate($startingAngle) scale(1);

    animation: enter $enterTime both ease-in 1;

    &:before,
    &:after{
      content: '';
      position: absolute;

      width: 6px;
      height: 6px;

      border-radius: 6px;

      background-color: $bulletColor;

      top: 50%;
      left: 0;
      transform-origin: 50% 50%;
      transform: translate( 0,-50%);

    }

    &:after{
      right: 0;
      left: auto;
    }


  }






}

@keyframes exit{
  0%{
    transform: translateX(-50%) translateY(-50%) rotateZ($finalAngle) scale(1);
     width: $barSize;
  }

  20%{
    width: $barSize;
    transform: translateX(-50%) translateY(-50%) rotateZ($finalAngle) scale(1);
  }

  100%{
    transform: translateX(-50%) translateY(-50%) rotateZ($startingAngle) scale(0);
    width: $barSize;
  }
}

@keyframes enter{

  0%{
    transform: translateX(-50%) translateY(-50%) rotateZ($startingAngle) scale(.1);
    width: $barSize;
  }

  20%{
    width: $barSize;
    transform: translateX(-50%) translateY(-50%) rotateZ($startingAngle) scale(1);
  }

  100%{
    transform: translateX(-50%) translateY(-50%) rotateZ($finalAngle) scale(1);
    width: $barSize;
  }

}


.slider-navigation-wrapper {

    position: relative;
    bottom: 0px;
    width: 100%;
    height: 50px;
    z-index: 10;
    text-align: center;

  @include respondTo("tb") {

    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY( -50% );
    width: 50px;
    height: 100px;

  }

}


	.slider-navigation-button {

    display: inline-block;
    background-color: $col-lightBrown;
		cursor: pointer;
		position: relative;
    width: 50px;
    height: 100%;
    margin: 0px -1px;
    transition: all .3s ease-in-out;


    @include respondTo("tb") {

      display: block;
  		width: 100%;
  		height: 0px;
  		padding-bottom: 100%;
      margin: 0px;

    }


		.o-slider--white & {

			background-color: $bulletColor;
			border-bottom: 1px solid #FFF;

		}

		.o-slider--lightBrown & {

			background-color: #FFF;
			border-bottom: $bulletColor;

		}

     &:after {

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 11px;
      height: 19px;

    }

    &:hover {

        background-color: white;

    }
	}


	.slider-button-prev {

    @include respondTo("tb") {
      margin-bottom: 1px;
    }

    &:after {

      content: url(/images/icons/arrow--left__white.svg);

    }

    &:hover {

      &:after {

        content: url(/images/icons/arrow--left.svg);

      }

    }

	}

	.slider-button-next {

    &:after {

      content: url(/images/icons/arrow--right__white.svg);

    }

    &:hover {

      &:after {

        content: url(/images/icons/arrow--right.svg);

      }

    }

	}

.o-slider--whiteBullets{

  .slider-pagination-bullet{
    @include bulletColor( white );
  }

}

.o-slider--cantineSlider{

  display: none;
  max-width: 1680px;
  margin: 0 auto;

  @include respondTo("tb") {

    display: block;

  }
}

.o-slider--homeHeroSlider {
  .swiper-pagination {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    right: 0;
    top: 0;
    bottom: 0;
    left: auto;
    position: absolute;
    width: 40px;
    // height: 100%;
  }
}