#contatti {
	
	a {
		
		color: $col-lightBrown;

	}

}


.contatti__title{
	color: $col-lightBrown;
}


.title--center{
	text-align: center;
}

.contatti--cartina {
	
	width: 100%;
	max-width: 950px;
	height: auto;

}

.country{
	ul{
		list-style: none;
		padding-left: 0;
	}
}

.country--list{

	list-style: none;
	display: flex;
	flex-wrap: wrap;

	li{

		flex: 100% 0 0;

		@include respondTo("tb"){
		
			flex: 33.3333333333% 0 0

		}
	}
}


.page-contatti_block{
	margin: 2rem 0 4rem;
}

.page-contatti_addresses{
	margin-bottom: 1rem;
}

.page-contatti_globe{
	margin-top: 100px;
}