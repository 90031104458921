$breakpoint-menu: $breakpoint-sm;

$menuOffset: -50px;

@include respondTo("tb") {

  $menuOffset: -70px;

}

.o-siteMenu{

  display: none;
  height: 100%;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-overflow-scrolling: touch;
  z-index:-1;

  .is-open & {
    display: block;
    overflow-y: scroll;
  }

  @include respondTo("tb") {

    min-height: 100vh;

    .is-open & {
      display: flex;
    }

  }


}

  .o-siteMenu__single {

    background-color: white;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex: 50% 1 0;
    justify-content: center;
    align-items: center;

    min-height: 50vh;

    padding: 2rem 4rem 2rem;
    transition: top cubic-bezier(1.000, 0.000, 0.000, 1.000) 1s;
    position:relative;

    &.is-open {
        top: 140px;
    }

    @include respondTo("tb") {

    padding: 5rem 1rem;

    }


      &--primary{

        padding-bottom: 1.5rem;
        background-color: $col-darkBrown;

        @include respondTo("tb") {
          padding-top: 2.5rem;
          padding-bottom: 5rem;
        }
      }

      &--products{

      padding-top: 1.2rem;

      .o-menu__item {

        position: relative;

        &:after {

          display: inline-block;
          position: absolute;
          content: "";
          border-bottom: 1px solid $col-lightBrown;
          width: 50px;
          height: 1px;
          left: 50%;
          top: -5px;
          transform: translateX(-50%);

        }

        &:first-child {

          &:after {

            display: none;

          }

        }

      }

      .o-menu__singleItem {

        text-transform: none;
        color: $col-darkBrown;
        font-family: $t-serif;
        font-weight: bold;

        font-size: _fs(25);
        line-height: 1.1em;

      }

      .o-menu__singleItem-description {
        font-size: .875rem;
        color: $col-lightBrown;
        font-family: $t-sans;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 1em;
      }

      }

  }



@media screen and ( min-width: $breakpoint-menu ){

  .o-siteMenu{
    flex-direction: row;
  }

  .o-siteMenu__single {

      display: flex;
      flex: 50% 1 0;

  }

}

  .o-menu__listItem {

    display: table-cell;
    vertical-align: middle;

  }


  .o-menu__listItem, .o-menu__sublistItem {

    margin: 0px;
    padding: 0px;
    text-align: center;
    list-style: none;
    text-transform: uppercase;

  }

    .o-menu__item {

      padding: 1rem 0;
      margin: 0px;

      @include respondTo("tb") {

        margin: 8px 0;

      }

    }

      .o-menu__singleItem, .o-menu__subsingleItem {

        display: block;
        font-size: 1.125rem;
        color: $col-lightBrown;

      }


      .o-menu__sublistItem {

        margin: 0;
        height:0px;
        overflow: hidden;

        &.is-open {

          margin: 11px 0;
          height: auto;

        }

      }


        .o-menu__subsingleItem {

          padding: 5px 0;
          opacity: 0.5;
          font-size: 0.875rem;

        }


  .o-menu__single--productsMenu {

    font-family: $t-serif;

    .o-menu__singleItem, .o-menu__subsingleItem {

      text-transform: none;
      font-size: 1.5625rem;
      font-weight: bold;
      color: $col-darkBrown;

    }

    .o-menu__singleItem:after{
      content: '';

      @include hr();
      margin-top: .75rem;
    }

  }


  .o-siteMenu__iconsMobile {

      display: flex;
      position: relative;
      top: 70px;
      -webkit-box-align: center;
      align-items: center;
      background: white;
      color: $col-lightBrown;;
      padding: 0 35px;
      height: 70px;
      font-size: 1.125rem;
      justify-content: space-between;


      .languageSwitcher {

        display: flex;
        margin-right: 2.5rem;
        text-transform: uppercase;
      }
      .languageSwitcher__dropdown {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;

        position: absolute;
        margin: 0;
        padding: 0 35px;

        width: 100%;
        height: 70px;

        left: 0;
        top: 70px;
        right: 0;

        z-index: -1;

        background-color: #fff;
        list-style: none;
      }
      @include respondTo("tb"){

      display: none;
      }

  }

  .o-siteMenu__icon--search {

      display: inline-block;
      width: 20px;
      margin-left: 2.5rem;
      height: 30px;
      position: relative;
      vertical-align: middle;
      height: 100%;

      svg {

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

  }

@media screen and ( max-width: 766px ){
  .o-siteMenu__single {
    top: 70px;
  }
}