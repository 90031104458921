.loading {
	position: fixed;
	z-index: 400;
	background-color: $col-darkBrown;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;

	mask: url('../images/sprite.png') no-repeat 0 0;
	mask-size: auto 1600%;

	mask-position: center 0%;

	// background-image: url(../images/icons/preloader.gif);
	background-position: center;
	background-size: auto;
	background-repeat: no-repeat;

	@include respondTo("xl") {
		
		mask-size: 100% 1600%;

	}

}

	.loading__wrapper {
		
		position: absolute;
		width: 100%;
		max-width: 200px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.loading__gif{
		display: block;
		margin: 0 auto 2rem;
		width: 100px;
	}

	.animateOut{

		animation: mask .8s steps(15) forwards;

	}

@keyframes fadeOut{
	
	from{
		opacity: 1;
	}

	to{
		opacity: 0;
	}

}

@keyframes mask{
  
  0%{
    mask-position: center 0%;
  }

  60%{
  	opacity: 1;
  }

  100%{
  	mask-position: center 100%;
  	opacity: 0;
  }
  
}