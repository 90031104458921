.container--404 {

	.o-block--whiteText .t-superTitle {

		color: white;

	}

	.o-block--whiteText .o-hr {

		border-color: white;

	}

}