#news {

	margin-top: ($heightMid * 2);

	h1 {
		
		line-height: 1;
		margin-bottom: $heightMid;

	}

	.news-yearSelector {
		
		display: block;
		margin: 0px auto $heightMid;
		width: 100%;
		max-width: 210px;

	}

}

.news-card {

	// max-width: 800px;
	margin: 0 auto;

	background-image: url(/images/icons/preloader.gif);
	background-position: center;
	background-repeat: no-repeat;
	background-color: $col-lightestGrey;
	background-size: auto 30px;
	display: block;
	padding: 1.75rem;
	cursor: pointer;
	margin-top: 20px;

	position: relative;

	&:first-child {
		
		margin-top: 0px;

	}

	&--open{
		cursor: inherit;
	}

}

	.news-card__wrapper {
		
		background-color: $col-lightestGrey;
		transition: opacity .3s ease-in-out;
			
		.is-loading & {
				
			opacity: 0;

		}

		@media screen and ( min-width: 600px ){
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.is-opened &{
			
			display: block;

		}

	}

		.news-card__content {
			
			position: relative;
			vertical-align: top;
			width: 100%;
			margin-bottom: 0.5rem;

			@include respondTo("lg"){
				
				margin-bottom: 0px;
				padding-right: 1rem;

			}

			a {

				color: $col-lightBrown;

			}

		}
			.news-card__date {
				
				font-size: 0.7em;

			}

			.news-card__title {

				//text-transform: uppercase;
				line-height: normal;
				margin-bottom: 0.5rem;
				font-weight: bold;

				font-weight: 600;

				color: $col-darkBrown;

			}

		.news-card__imageWrapper {
			
			width: 100%;
			position: relative;

			@include respondTo("tb"){
				
				max-width: 330px;
				margin-left: 1rem;

			}

			.is-opened &{
				
				max-width: 100%;
				margin: 0 0 1rem 0;

			}

		}

			.news-card__image {
				
				display: block;
				width: 100%;
				height: auto;

			}

.news-card__social{
	
	margin-bottom: 1rem;
	display: block;
	line-height: 0;

	@include respondTo("tb"){

		position: absolute;

		bottom: 1.75rem;
		left: 1.75rem;

		margin: 0;

	}
}

.news-card--open{
	.news-card__imageWrapper {
		max-width: initial;
		margin: 0;
		margin-bottom: 2rem;

	}

	.news-card__social{
		position: static;
		margin-bottom: 2rem;

		margin-left: 0;
	}

	.news-card__wrapper{
		display: block;
	}

	.news-card__heading{
		margin-bottom: 2rem;
	}

	.news-card__content{
		margin-bottom: 2rem;
	}
}