.overlayBox {
	
	display: none;
	position: fixed;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background: #FFF;
	z-index: 10;

	&.is-active {

		display: flex;
		align-items: center;
		justify-content: center;

	}

}

	.overlayBox__close {
		
		cursor: pointer;
		height: 50px;
		width: 50px;
		position: absolute;
		right: 0px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 2;
		background-color: $col-lightBrown;
		transition: all .3s ease-in-out;

		&:after {
			
			content: url(/images/icons/icon--close__white.svg);
			position: absolute;
			width: 16px;
			height: 19px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

		}

		&:hover {
			
			background-color: white;

			&:after {

				content: url(/images/icons/icon--close.svg);

			}

		}

	}

	.overlayBox__content {
				    
	    height: 100%;
	    width: 100%;

		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}

	.overlayBox__loading {
		
		background-image: url("/images/icons/preloader.gif");
		background-position: center;
		background-repeat: no-repeat;
	    bottom: 0;
	    left: 0;
	    position: absolute;
	    right: 0;
	    top: 0;

	}