$numberField: '.ui-numberField';


#{$numberField} {

	height: $heightMid;
	width: 100%;
	display: flex;
	align-items: center;

}

	#{$numberField}Action {

		cursor: pointer;
		width: 15px;
		opacity: 1;
		transition: opacity .3 ease-in-out;

		&:hover {

			opacity: .7;

		}

	}

	#{$numberField}--current {
		
		width: $heightMid;
		height: $heightMid;
		line-height: $heightMid;
		border: 1px solid $col-lightBrown;
		text-align: center;
		color: $col-lightBrown;
		margin: 0 10px;
		-webkit-user-select: none;  
		  -moz-user-select: none;    
		  -ms-user-select: none;      
		  user-select: none;

	}