.storia-navigation{
	position: fixed;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	display: block;
	font-size: 12px;
	line-height: 24px;
	font-weight: 500;
	text-align: right;

	display: none;
}

	.storia-navigation__year{

		display: block;
		vertical-align: middle;
		position: relative;

		width: 60px;

		height: 20px;

		cursor: pointer;

		transform: translateX(16px);

		&:hover{
			.storia-navigation__yearNumber{
				opacity: 1;
			}

			.storia-navigation__yearLine{
				background-color: $col-darkBrown;
			}

			.storia-navigation__yearDot{
				background-color: $col-darkBrown;
			}
		}

	}

		.storia-navigation__yearNumber{
			opacity: 0;

			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		.storia-navigation__yearLine{

			display: inline-block;
			height: 1px;
			width: 26px;

			position: absolute;
			right: 0;
			top: 50%;

			margin-top: -2px;

			background-color: $col-lightBrown;
		}

		.storia-navigation__yearDot{

			content: '';
			
			display: block;

			position: absolute;
			right: 26px;
			top: 50%;

			margin-top: -4px;

			width: 5px;
			height: 5px;

			border-radius: 4px;

			background-color: $col-lightBrown;

		}

	.storia-navigation__year--decade{
		transform: translateX(6px);
	}

	.storia-navigation__decadeGroup{
		.storia-navigation__years{
			display: none;
		}

		&.is-active{

			.storia-navigation__years{
				display: block;
			}

			.storia-navigation__year--decade{

				.storia-navigation__yearNumber{
					display: block;
					opacity: 1 !important;
				}

			}

		}
	}

	.storia-navigation__year{
		&.is-active{
			.storia-navigation__yearNumber{
				opacity: 1;
			}
		}
	}