.storia-year{
	text-align: center;
}

	.storia-year__number{
		font-size: 7rem;
		color: $col-lightBrown;

		font-family: $t-serif;
		margin-bottom: -1.157rem;

		position: relative;
		z-index: 3;
	}