.t-center {

	text-align: center;

}

.t-left {

	text-align: left;

}

.t-right {

	text-align: right;

}

.t-uppercase {

	text-transform: uppercase;

}


.t-pageTitle {

	font-family: $t-serif;
	font-weight: bold;
	font-size: _fs(30);
	line-height: 1.16em;

	@include respondTo("tb") {

		font-size: _fs(48);

	}

}

.t-pageSubtitle {

	font-family: $t-serif;
	font-weight: bold;

	font-size: 1.25rem;
	line-height: 1.4em;

}

.t-pageTitle + .t-pageSubtitle{

	margin-top: -1rem;

}

.t-contentTitle {

	font-family: $t-serif;
	font-weight: bold;
	font-size: _fs(30);
	line-height: _fs(40);

	@include respondTo("tb") {

		font-size: _fs(40);
		line-height: _fs(50);

	}

}

.t-smallTitle {

	font-family: $t-serif;
	font-weight: bold;
	font-size: 1.5rem;
    line-height: 1.5;

	@include respondTo("tb") {

		font-size: _fs(30);
		line-height: _fs(40);

	}

	&--black {

		color: $col-dark;

	}

}

.t-superTitle{
	font-family: $t-sans;
	font-weight: normal;
	font-size: _fs(12);
	line-height: 1.25;

	color: $col-lightBrown;

	text-transform: uppercase;
	margin-bottom: -.5rem;

	.o-heading--whiteText & {
		color: white;
	}
}

.t-descriptionTitle {

	font-family: $t-serif;
	font-weight: bold;
	font-size: _fs(20);
	line-height: _fs(30);

}

	.t-description {

		font-family: $t-sans;
		font-size: _fs(20);
		line-height: _fs(30);

	}

	.t-info {

		font-family: $t-sans;
		font-size: _fs(16);
		line-height: 1.625;

	}

  .t-link {
    text-decoration: underline;
  }


@media screen and ( min-width: 1025px ){
	.t-pageTitle{
		font-size: _fs(36);
	}

	.t-pageSubtitle{
		font-size: _fs(25);
	}
}
