@media screen and ( min-width: 1025px ){

	.storia-navigation{
		display: block;
	}

	.storia-wrapper{
		background-color: white;
		color: $col-dark;
	}

	.storia-container{
		display: flex;

		position: relative;
	}

	.storia-viewport{
		position: absolute;
		top: 0;
		left: 0;
		height: 100vh;

		display: block;

		width: 50vw;

		background-color: $col-darkBrown;

		z-index: 3;
	}

		.storia-viewport__year{
			position: absolute;
			font-size: 7.5rem;
			color: $col-lightBrown;

			font-family: $t-serif;
			top: 50%;

			z-index: 4;

			margin-left: 100px;

			transform: translateY(-50%);
		}

		.storia-viewport__picture{
			position: absolute;
			top: 50%;

			right: -60px;
			width: 60%;
			min-width: 400px;

			z-index: 3;

			transform: translateY(-50%);

			box-shadow: 0 0 20px rgba($col-dark, .4 );

			img{
				width: 100%;
				display: block;
				margin: 0;
			}
		}

		.storia-wrapper{
			width: 50%;
			position: relative;
			margin-left: 50%;
		}

		.storia-hero{
			margin-top: 5rem;
			height: auto;
			min-height: auto;
			max-width: 400px;
			padding-bottom: 0;
		}

		.storia-year__number{
			display: none;
		}

		.storia-episode{
			max-width: 420px;
			margin: 0 auto;
		}

			.storia-episode__picture{
				display: none;
			}

			.storia-episode__text{
				font-size: 1.25rem;
			}

}
