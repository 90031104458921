.ui-selectStyled__currentValue, .ui-selectStyled {

	width: 100%;
	height: $buttonHeight;
	line-height: $buttonHeight;
	display: block;
	text-transform: uppercase;
	color: $col-lightBrown;
	font-family: $t-sans;
	font-size: _fs(14);

	&:hover {
		cursor: pointer;
	}
}

.ui-selectStyled__currentValue {

	position: relative;
	z-index: 2;
	border: 1px solid $col-lightBrown;
	background-color: white;
	padding: 0 20px;

	&:after {

		content: url("/images/icons/arrow--down.svg");
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		width: 14px;

	}

	.is-open & {
			
		border-bottom: none;


		&:after {
			
			transform: translateY(calc(-50% - 1px));

		}

	}
}


.ui-selectStyled {

	position: absolute;
	list-style: none;
	margin: 0px;
	padding: 0px;
	bottom: 0;

	.is-open & {

		top: 50px;
		z-index: 200;

	}

}

	.ui-selectStyled__option {

		display: none;
		background: white;
		color: $col-darkBrown;
		padding: 0 20px;
		background-color: white;
		border-left: 1px solid $col-lightBrown;
		border-right: 1px solid $col-lightBrown;

		&:last-child {
			
			border-bottom: 1px solid $col-lightBrown;

		}

		&:hover, &.is-selected {
			color: white;
			background-color: $col-lightBrown;

		}

		.is-open & {
			
			display:block;

		}

	}