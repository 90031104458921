.o-tabsBlock {

	display: block;
	margin: 60px auto;
	max-width: 1680px;
	width: 100%;


	&__tabsWrapper {

		max-width: 690px;
		margin: 0 auto;

	}

	&__container > * {
		
		position: absolute;
		left:-9999px;

		&.is-selected {
			
			position: static;

		}

	}


	&__single {
		
		padding: 60px 0 0;
		text-align: center;

	}

}

