.logoNonino--hero {

	max-width: 420px;
	margin-bottom: 1.5rem;

}

.logoPremioNonino--mid,{
	
	max-width: 280px;

}

.logoManosBlancas,{
	
	max-width: 145px;

}


#firma-altan {

	display: inline-block;
    position: relative;
    vertical-align: middle;

}