.o-heading--image {
	
	width: 100%;
	height: auto;

}

.o-heading--white {

	> * {

		color: white;

	}

	.o-hr {
		
		border-color: white;

	}

}