.hero{
	color: white;
	background-color: $col-dark;

	display: flex;
	justify-content: left;
	align-items: center;

	position: relative;

	text-align: center;

	max-width: 1680px;
	margin: 0 auto;

	height: 100vh;

}

	.hero__container{

		left: 0px;
		top: 50%;
		transform: translateY(-50%);
		width: 50%;

		position: absolute;
		z-index: 2;

		max-width: 400px;
		margin-left: _fs(100);
	}

	.hero__content{
		font-size: _fs(20);
	}

	.hero__background{
		
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		z-index: 1;

		background-size: cover;
		background-position: bottom center;
		background-repeat: no-repeat;
	}

	.hero__backgroundHero {

		width: 100%;

	}

	.hero__imageHero  {
		
		width:100%;
		display:block;
		margin: 0 auto;

	}

	.hero__buyWrapper {

		width: 375px;		
		position: absolute;
		right: 0;
		bottom: 0;

		z-index: 2;

	}