.main {
	
	opacity: 1;

	html.is-loading & {
		
		opacity: 0;

	}

}

.page {

	text-align: left;
	display: block;
	margin: 0 auto;
	padding: ($heightMid * 2) 15px;
	width: 100%;
	max-width: 1000px;

	h1 {
		
		text-align: center;

	}

}

#products, #storia {
	
	padding-top: 70px;

	@include respondTo("tb") {
		
		padding-top: 0px;

	}

}