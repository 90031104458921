$cart : ".o-cartWrapper";

#{$cart} {
	
	display: none;

	background-color: white;
	padding: 30px;
	text-align: left;

}

	#{$cart}__header {

		text-align: left;

	}

		#{$cart}__label, #{$cart}__label$col-dark {
			
			line-height: 1;
			margin-bottom: 20px;

		}

		#{$cart}__label$col-dark {
			
			color: $col-dark;

		}

	#{$cart}__capacitaList {

		display: flex;

	}

	#{$cart}__quantita {

		margin: 40px 0 170px;
		display: flex;
    	justify-content: space-between;

    	> * {

			display: inline-block;
    		position: relative;
    		vertical-align: top;
    		line-height: $heightMid;

    	}

	}