.o-slider--cocktailSliderHome {

	.o-block {
			
		padding: 4rem 2rem 2rem;

	}

}



.cocktailsSlider{
	display: none;
	height: 100vh;
	position: relative;
	max-width: 1680px;
    margin: 0 auto;
}

	.cocktailsSlider-wrapper{
		height: 100%;
		overflow: hidden;

		position: relative;
	}

	.cocktailsSlider-navigation{
		position: absolute;
		bottom: 0;
		top: auto;
		left: 50%;
		transform: translateX(-50%);

		color: white;

		z-index: 60;

		height: 60px;

		display: block;
		flex-direction: column;
		justify-content: space-between;

		@include respondTo("tb") {
			
			display: flex;
			top: 0px;
			bottom: auto;
			height: 100%;

		}
	}

		.cocktailsSlider-navigation__arrow{

			background-color: $col-lightBrown;
			position: relative;
			display: inline-block;
			width: 60px;
			height: 60px;

			line-height: 60px;
			text-align: center;

			cursor: pointer;

			svg {
				
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 18px;
				height: 10px;
				transform: rotate(-90deg);
				margin-top: -5px;
    			margin-left: -10px;

				path {
					
					fill: white;

				}

			}

			@include respondTo("tb") {
			
				background-color: white;

				svg {
					
					transform: rotate(0deg);
					path {
					
						fill: $col-lightBrown;

					}

				}

			}
		}

	.cocktailSlide{
		width: 100%;
		height: 100%;
		display: flex;

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		z-index: 3;

		display: none;

		&.is-active{
			display: flex;
			z-index: 4;
		}
	}

	.cocktailSlide-index{
		position: absolute;

		top: 50%;
		left: 50%;

		transform: translateX(-50%) translateY(-50%);

		width: 60px;
		height: 60px;

		z-index: 20;
	}

	.cocktailSlide-half{

		flex: 100%;

		background-color: white;

		text-align: center;

		display: flex;
		justify-content: center;

		padding-top: 2rem;

		background-size: auto 100%;
		background-position: center 100%;
	}

		.cocktailSlide-half__wrapper{
			max-width: 480px;
			margin: 0 auto;
			width: 90%;
		}

	.cocktailSlide-half--grappa{
		color: white;
		display: none;
	}

	.cocktailSlide-cocktail__text{
		display: none;
		font-size: 20px;
		line-height: 1.5em;
		margin-bottom: 2rem;

		@include respondTo("tb") {

			display: block;

		}
	}

	.cocktailSlide-cocktail__cta{
		margin-bottom: 2rem;
	}

	.cocktailSlide-grappa{
		color: white;
	}


	.cocktailSlide-cocktail__picture {

		display: block;
		width: 70%;
		margin: 2rem auto;
		
		img {
			
			width: 100%;

		}

		@include respondTo("lg") {
			
			margin: 0 auto;

		}

	}

	.cocktailSlide-cocktail__pictureMobile {

		display: block;
		width: 100%;
		margin: 2rem auto;
		
		img {
			
			width: 100%;

		}

		@include respondTo("lg") {
			
			margin: 0 auto;

		}

	}


@media screen and ( min-width: 414px ){
	.cocktailSlide-half{
		padding-top: 5rem
	}

}


@media screen and ( min-width: 1025px ){

	.cocktailsSlider {
		
		display: block;

	}

	.o-slider--cocktailSliderHome {
		
		display: none;

	}

	.cocktailsSlide-half{
		flex: 50% 0 0;
	}

	.cocktailSlide-half--grappa{
		display: flex;
	}

}