.o-grid{
	display: flex;
	flex-wrap: wrap;
	max-width: 1680px;
	margin: 0 auto;
	justify-content: center;
}

	.o-grid__item{
		// display: none;
		width: 100%;
		margin-bottom: 1rem;

		&.is-visible {
			
			display: flex;

		}
	}

	@media screen and ( min-width: 620px ){

		.o-grid__item{
			width: (100%/2);
		}

	}

	@media screen and ( min-width: 900px ){

		.o-grid__item{
			width: (100%/3);
		}

	}

	