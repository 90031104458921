@mixin hr {

	display: block;

	margin: 0 auto;
	
	width: 52px;
	border: none;
	border-bottom: 1px solid $col-lightBrown;
	height: 0;

}