@import "e-typography";
@import "e-button";
@import "e-input";
@import "e-blocksGrid";
@import "grid";

@import "e-tabsBlock";
@import "e-filtersBlock";
@import "e-block";
@import "e-imageBlock";
@import "e-heading";
@import "e-select";
@import "e-selectStyled";
@import "e-numberField";
@import "e-listElements";

@import "e-hr";
@import "e-slider";

@import "e-overlayBox";
@import "e-tabs";
@import "e-filters";
@import "e-social";

// riportare questi file in modulo specifico ( ie. cocktail, product, ecc )
// @import "e-blockCocktail";
// @import "e-cocktailCard";

// rimuovere questi file:
//@import "e-productCard";

.c-dotSeparator{
	transform: translateY(-0.13em);
	display: inline-block;
}