.news-sidebar{

	position: relative;
	vertical-align: top;
	background-color: white;
	display: inline-block;
	width: 100%;
	padding: 0 15px;
	margin-top: $heightMid;

	@include respondTo("tb") {

		width: 100%;

		padding: 0 105px;

	}

	@include respondTo("lg") {

		width: 25%;

		padding: 0 105px 0 0;
		margin-left: -4px;

	}


	.form__newsletter {

		max-width: 100%;
		
		display: none;

		@include respondTo("lg") {

			display: block;

		}

		h5 {
			
			margin-bottom: 1rem;
			color: $col-dark;

		}

	}

}

	.widget_news-sidebar__video {

		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%;
		padding-top: 30px;
		position: relative;
		margin-top: 1rem;

		iframe, object, embed {

			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;

		}

	}