.o-socials {
	
	width:100%;
	list-style: none;
	margin: 0;
	padding: 0;

	line-height: 0;

	display: flex;
	justify-content: center;

	li {
		
		display: inline-block;
		
		width: 18px;
		height: 18px;


		line-height: 0;
		margin: 0 12px;

		&:last-child{
			
			width: 22px;
			margin-left: 10px;
			margin-right: 0;

		}

	}

}	

	.o-social {

		display: inline-block;
		width: 100%;
		height: 100%;

		&--yt {
		
			width: 25px;

		}

		svg {
			
			width: 100%;
			height: 100%;

		}

	}

.o-socials--grey{
	.o-social{
		color: #dadada;

		svg > * {
			fill: #dadada;
		}

		&:hover{
			svg > * {
				fill: $col-lightBrown;
			}
		}

	}
}


.o-social--link {
	
	position: relative;
	cursor: pointer;

}


.clipBoardBalloon {
	
	cursor: default;
	opacity: 0;

    background: #b2b2b2;
	
	position: absolute;
    top: -33px;
    left: 50%;
    
    display: flex;
    align-items: center;
 
    height: 25px;
    padding: 0 10px;
    transform: translateX(-50%);
    transition: opacity .3s ease-in-out 0s; 

	i {
		
		position: absolute;
    	left: 50%;
    	bottom: -8px;
    	transform: translateX(-50%);
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 8px 5px 0 5px;
		border-color: #b2b2b2 transparent transparent transparent;

	}

	span {
		
		font-family: $t-sans;
		font-size: 0.7rem;
		font-weight: bold;

		text-transform: uppercase;
		color: white;
	    display: inline-block;
	    position: relative;
	    vertical-align: middle;

	    white-space: nowrap;

	}


	.is-copied & {
		
		opacity: 1;

	}

}