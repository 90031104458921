$tabs: '.ui-tabs';

.tabsWrapper {

	width: 100%;
	max-width: 690px;
	margin: 60px auto 30px;

}


#{$tabs} {

	position: relative;
	z-index: 2;
	width: 100%;
	display: flex;
	margin: 0 auto;
	list-style: none;
	padding: 0px;
	text-align: center;

	#{$tabs}__single {
		
		display: flex;
		flex-grow: 1;
		flex-basis: 0;
		flex-direction: column;
		justify-content: center;

		border-top: 1px solid $col-lightBrown;
		border-bottom: 1px solid $col-lightBrown;

		background: none;

		font-family: $t-sans;
		font-size: _fs(15);
		line-height: 1.5;
		padding: 0.85rem;

		color: $col-lightBrown;

		text-align: center;

		min-width: 200px;

		cursor: pointer;
		text-transform: uppercase;

		position: relative;

		transition: all .3s ease-in-out;

		&.is-active, &:hover, &:active {
	
			color: white;
			background-color: $col-lightBrown;

		}

		&:hover {

			transition: all .3s .3s ease-in-out;
		
			i {
				
				&:before,
				&:after{
					transition: all .3s ease-in-out;
				}

				&:before{
					bottom: 50%;
				}

				&:after{
					top: 50%;
				}
				
			}

		}

		i {
			
			&:before,
			&:after{
				content: '';
				border: 1px solid $buttonColor;
				border-top: none;
				border-bottom: none;
			
				position: absolute;
				left: 0;
				right: 0;
				
				transition: all .3s ease-in-out;
			}
			
			&:before{
				top: 0;
				bottom: 100%;
			}
			
			&:after{
				top: 100%;
				bottom: 0;
			}
			
		}
	
	}

}