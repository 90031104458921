.storia-episode{
	margin: 0 auto;
	max-width: 400px;
	width: 90%;
	text-align: center;
	padding-bottom: 140px;
	position: relative;

	&:after{
		content: '';
		
		transition: all .3s ease-out;
		width: 1px;
		background-color: $col-lightBrown;

		position: absolute;
		bottom: 20px;

		height: 100px;

		left: 50%;
	}

	&:last-child{

		&:after{
			content: none;
		}

	} 

	&:before{
		content: attr(data-year);

		font-size: 7.5rem;
		color: $col-lightBrown;

		font-family: $t-serif;

		z-index: 4;
		
		text-align: center;
		width: 100%;
		display: block;

		line-height: 0.84em;
		z-index: 4;
		position: relative;

		@media screen and ( min-width: 1025px ){

			content: none;

		}
	}

}

	.storia-episode__picture{

		margin: 0 auto;
		margin-bottom: 1rem;
		display: block;

		img{
			width: 100%;
			display: block;
		}
	}

		.storia-episode__title{
			color: $col-lightBrown;
			text-transform: uppercase;
			padding-top: 2rem;

			font-size: .875rem;
		}

		.storia-episode__text{
			margin: 0;
			padding-bottom: 3rem;
		}