@charset "UTF-8";
/**
 * Mixin utilizzati in tutto il sito
 */
.logoNonino--hero {
  max-width: 420px;
  margin-bottom: 1.5rem;
}

.logoPremioNonino--mid {
  max-width: 280px;
}

.logoManosBlancas {
  max-width: 145px;
}

#firma-altan {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

/**
 * Swiper 3.4.2
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 * 
 * http://www.idangero.us/swiper/
 * 
 * Copyright 2017, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 * 
 * Licensed under MIT
 * 
 * Released on: March 10, 2017
 */
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height;
}

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}

/* Bullets */
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  -moz-transform: translate3d(0px, -50%, 0);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
}

/* Progress */
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
}

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff;
}

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000;
}

/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
}

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 16+, IE10, Opera 12.50+ */
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 16+, IE10, Opera 12.50+ */
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 16+, IE10, Opera 12.50+ */
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 16+, IE10, Opera 12.50+ */
}

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px;
}

/* Cube + Flip */
.swiper-container-cube,
.swiper-container-flip {
  overflow: visible;
}

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* Cube */
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
}

.swiper-zoom-container > img,
.swiper-zoom-container > svg,
.swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

* {
  outline: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

::selection {
  background: #A67556;
  /* WebKit/Blink Browsers */
  color: white;
}

::-moz-selection {
  background: #A67556;
  /* Gecko Browsers */
  color: white;
}

body {
  margin: 0px;
  font-size: 16px;
  color: #21160D;
  color: #21160D;
  line-height: 1.75em;
  font-family: "brandon-grotesque", sans-serif;
  letter-spacing: 0.02em;
  -webkit-font-smoothing: antialiased;
}

html.prevent-scroll,
html.prevent-scroll body {
  height: 100%;
  overflow: hidden;
}

h1, h2, h3, h4, h5 {
  font-weight: normal;
  line-height: 1em;
  margin: 0 0 1rem;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0px;
}

.logoNonino--hero {
  max-width: 420px;
  margin-bottom: 1.5rem;
}

.logoPremioNonino--mid {
  max-width: 280px;
}

.logoManosBlancas {
  max-width: 145px;
}

#firma-altan {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.t-center {
  text-align: center;
}

.t-left {
  text-align: left;
}

.t-right {
  text-align: right;
}

.t-uppercase {
  text-transform: uppercase;
}

.t-pageTitle {
  font-family: "Playfair Display", serif;
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 1.16em;
}

@media screen and (min-width: 767px) {
  .t-pageTitle {
    font-size: 3rem;
  }
}

.t-pageSubtitle {
  font-family: "Playfair Display", serif;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.4em;
}

.t-pageTitle + .t-pageSubtitle {
  margin-top: -1rem;
}

.t-contentTitle {
  font-family: "Playfair Display", serif;
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.5rem;
}

@media screen and (min-width: 767px) {
  .t-contentTitle {
    font-size: 2.5rem;
    line-height: 3.125rem;
  }
}

.t-smallTitle {
  font-family: "Playfair Display", serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5;
}

@media screen and (min-width: 767px) {
  .t-smallTitle {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
}

.t-smallTitle--black {
  color: #21160D;
}

.t-superTitle {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.25;
  color: #A67556;
  text-transform: uppercase;
  margin-bottom: -.5rem;
}

.o-heading--whiteText .t-superTitle {
  color: white;
}

.t-descriptionTitle {
  font-family: "Playfair Display", serif;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.875rem;
}

.t-description {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1.25rem;
  line-height: 1.875rem;
}

.t-info {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1rem;
  line-height: 1.625;
}

.t-link {
  text-decoration: underline;
}

@media screen and (min-width: 1025px) {
  .t-pageTitle {
    font-size: 2.25rem;
  }
  .t-pageSubtitle {
    font-size: 1.5625rem;
  }
}

.ui-button {
  background: none;
  border: none;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 0.875rem;
  padding: 0 2rem;
  margin-bottom: 1rem;
  text-align: center;
  display: inline-block;
  min-width: 200px;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
}

.ui-button__inner {
  position: relative;
  z-index: 10;
  padding: 0.813rem 0;
  line-height: 1.5rem;
}

.ui-button--full {
  width: 100%;
  display: block;
}

.ui-button--cta {
  color: #A67556;
  transition: all .3s ease-in-out;
}

.ui-button--cta:before, .ui-button--cta:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  right: 0;
  transform: translateX(-50%);
  width: 52px;
  z-index: 3;
  transition: all .3s .3s ease-in-out;
}

.ui-button--cta:before {
  border-top: 1px solid #A67556;
}

.ui-button--cta:after {
  border-bottom: 1px solid #A67556;
}

.ui-button--cta i:before, .ui-button--cta i:after {
  content: '';
  border: 1px solid #A67556;
  border-top: none;
  border-bottom: none;
  position: absolute;
  left: 0;
  right: 0;
  transition: all .3s ease-in-out;
}

.ui-button--cta i:before {
  top: 0;
  bottom: 100%;
}

.ui-button--cta i:after {
  top: 100%;
  bottom: 0;
}

.ui-button--cta:hover {
  background-color: #A67556;
  transition: all .3s .4s ease-in-out;
  color: white;
}

.ui-button--cta:hover:before, .ui-button--cta:hover:after {
  width: 100%;
  transition: width .3s ease-in-out;
}

.ui-button--cta:hover i:before, .ui-button--cta:hover i:after {
  transition: all .3s .3s ease-in-out;
}

.ui-button--cta:hover i:before {
  bottom: 50%;
}

.ui-button--cta:hover i:after {
  top: 50%;
}

.ui-button--ctaProduct {
  margin-top: 2.5rem;
  margin-bottom: 0px;
}

.ui-button--ctaRicetta {
  margin-top: 0px;
  margin-bottom: 2.5rem;
}

.ui-button--progress {
  background-color: #A67556;
  color: white;
  transition: all .3s ease-in-out;
}

.ui-button--progress i:before, .ui-button--progress i:after {
  background: #21160D;
  content: '';
  border-top: none;
  border-bottom: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0px;
  transition: all .3s ease-in-out;
}

.ui-button--progress:hover i:before, .ui-button--progress:hover i:after {
  width: 100%;
  transition: all .3s 0s ease-in-out;
}

.ui-button--white {
  color: white;
}

.ui-button--white, .ui-button--white:before, .ui-button--white:after, .ui-button--white *, .ui-button--white *:before, .ui-button--white *:after {
  border-color: white !important;
}

.ui-button--white:hover {
  color: #A67556;
  background-color: white;
}

.ui-button--buy {
  background: #A67556;
  color: white;
  display: block;
  margin: 0px;
  height: 70px;
  transition: all .3s ease-in-out;
}

.ui-button--buy .ui-button__inner {
  line-height: 2.75rem;
}

.ui-button--buy i:after {
  display: block;
  color: #A67556;
  width: 17px;
  height: 17px;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (min-width: 767px) {
  .ui-button--buy {
    background: white;
    color: #A67556;
  }
}

.ui-button--capacita {
  cursor: pointer;
  width: 100%;
  padding: 0px;
  background-color: white;
  border: 1px solid #DADADA;
  height: 70px;
  margin: 0 0 20px;
  transition: border-color .3s ease-in-out;
}

.ui-button--capacita:hover, .ui-button--capacita.is-selected {
  border-color: #A67556;
}

.ui-button--capacita:nth-child(1n + 2) {
  margin-left: 20px;
}

.ui-button--solid {
  color: white;
  background-color: #A67556;
}

.ui-input {
  position: relative;
  border: 1px solid #A67556;
  border-left: none;
  border-right: none;
  text-align: left;
}

.ui-input i:before, .ui-input i:after {
  content: '';
  border: 1px solid #A67556;
  border-top: none;
  border-bottom: none;
  position: absolute;
  left: 0;
  right: 0;
  transition: all .3s ease-in-out;
}

.ui-input i:before {
  top: 0;
  bottom: 100%;
}

.ui-input i:after {
  top: 100%;
  bottom: 0;
}

.ui-input.is-focus {
  background-color: white;
  transition: all .3s 0 ease-in-out;
  color: #A67556;
}

.ui-input.is-focus:before, .ui-input.is-focus:after {
  width: 100%;
  transition: width .3s ease-in-out;
}

.ui-input.is-focus i:before, .ui-input.is-focus i:after {
  transition: all .3s ease-in-out;
}

.ui-input.is-focus i:before {
  bottom: 50%;
}

.ui-input.is-focus i:after {
  top: 50%;
}

.ui-input__label {
  font-size: 14px;
  position: absolute;
  text-transform: uppercase;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  letter-spacing: -0.02em;
  transition: all .3s ease;
  color: #A67556;
}

.is-focus .ui-input__label {
  top: -3px;
  left: 1rem;
  transform: translateY(0);
  font-size: 10px;
}

.ui-input__field {
  background: transparent;
  position: relative;
  border: none;
  height: 50px;
  padding: 0 1rem;
  text-transform: uppercase;
  color: #21160D;
  width: 100%;
  text-align: center;
  font-family: "brandon-grotesque", sans-serif;
  text-align: left;
}

.ui-input__field:focus {
  outline: none;
}

.ui-input__field ::-webkit-input-placeholder {
  color: #A67556;
}

.ui-input__field :-moz-placeholder {
  color: #A67556;
}

.ui-input__field ::-moz-placeholder {
  color: #A67556;
}

.ui-input__field :-ms-input-placeholder {
  color: #A67556;
}

.o-blocksGrid {
  display: flex;
  margin: 0 auto;
  max-width: 1680px;
  width: 100%;
  flex-direction: column;
  position: relative;
}

.o-blocksGrid--homeHero {
  height: 100vh;
  display: flex;
  background-color: #21160D;
}

.o-blocksGrid--homeHero .o-blocksGrid__column {
  height: 100%;
}

@media screen and (min-width: 767px) {
  .o-blocksGrid--homeHero {
    min-height: 600px;
    height: 90vh;
  }
}

.o-blocksGrid--rowTemplate__img--dx {
  flex-direction: column-reverse;
}

@media screen and (min-width: 767px) {
  .o-blocksGrid {
    flex-direction: initial;
  }
}

.o-blocksGrid__column {
  overflow: hidden;
  display: block;
  flex-direction: column;
  padding: 0;
  height: auto;
  width: 100%;
}

@media screen and (min-width: 960px) {
  .o-blocksGrid {
    min-height: 560px;
    display: flex;
  }
  .o-blocksGrid--big {
    height: 740px;
  }
  .o-blocksGrid--hero {
    height: 100vh;
  }
  .o-blocksGrid--homeHero {
    height: 100vh;
    width: 100%;
    max-width: inherit;
    padding-top: 0;
  }
  .o-blocksGrid--small {
    min-height: 375px;
  }
  .o-blocksGrid--slider {
    min-height: 330px;
  }
  .o-blocksGrid--quote {
    min-height: 450px;
  }
  .o-blocksGrid__column {
    display: flex;
    background-color: white;
    flex: 50% 1;
  }
  .o-blocksGrid__column--small {
    flex: 40% 0;
  }
  .o-blocksGrid__column--smallest {
    flex: 375px 0;
  }
  .o-blocksGrid__column--big {
    flex: 60% 0;
  }
}

.o-grid {
  display: flex;
  flex-wrap: wrap;
  max-width: 1680px;
  margin: 0 auto;
  justify-content: center;
}

.o-grid__item {
  width: 100%;
  margin-bottom: 1rem;
}

.o-grid__item.is-visible {
  display: flex;
}

@media screen and (min-width: 620px) {
  .o-grid__item {
    width: 50%;
  }
}

@media screen and (min-width: 900px) {
  .o-grid__item {
    width: 33.3333333333%;
  }
}

.o-tabsBlock {
  display: block;
  margin: 60px auto;
  max-width: 1680px;
  width: 100%;
}

.o-tabsBlock__tabsWrapper {
  max-width: 690px;
  margin: 0 auto;
}

.o-tabsBlock__container > * {
  position: absolute;
  left: -9999px;
}

.o-tabsBlock__container > *.is-selected {
  position: static;
}

.o-tabsBlock__single {
  padding: 60px 0 0;
  text-align: center;
}

.o-filtersBlock {
  display: block;
  margin: 0px auto;
  max-width: 1680px;
  width: 100%;
}

.o-filtersBlock__filters {
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
  margin: 0 auto;
  list-style: none;
  padding: 0px;
  text-align: center;
}

.o-filtersBlock__filter {
  color: #A67556;
  cursor: pointer;
  display: inline-block;
  padding: 0 15px;
  position: relative;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;
}

.o-filtersBlock__filter:before {
  opacity: 0;
  position: absolute;
  top: -1rem;
  left: 50%;
  display: block;
  height: 20px;
  width: 39px;
  background-image: url(/images/icons/icon--menu.svg);
  background-position: center;
  background-repeat: no-repeat;
  transform: translateX(-50%);
}

@media screen and (min-width: 970px) {
  .o-filtersBlock__filter:before {
    content: '';
  }
}

.o-filtersBlock__filter:after {
  content: "•";
  line-height: 1;
  position: absolute;
  right: -7.5px;
  top: 40%;
  transform: translate3d(-50%, -50%, 0);
  color: #21160D;
}

.o-filtersBlock__filter.is-active, .o-filtersBlock__filter:hover, .o-filtersBlock__filter:active {
  color: #21160D;
}

.o-filtersBlock__filter.is-active:before, .o-filtersBlock__filter:hover:before, .o-filtersBlock__filter:active:before {
  opacity: 1;
}

.o-filtersBlock__filter:last-child:after {
  content: "";
}

.o-filtersBlock__container {
  position: static;
  padding: 60px 0 0;
  text-align: center;
}

.o-block {
  overflow: hidden;
  width: 100%;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-grow: 2;
  padding: 2rem;
}

@media screen and (min-width: 767px) {
  .o-block {
    padding: 4rem 2rem;
  }
}

.o-block__wrapper {
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.o-block__wrapper > * {
  margin-bottom: 1rem;
}

.o-block__wrapper > *:last-child {
  margin-bottom: 0;
}

.o-block__container {
  position: relative;
  z-index: 3;
  width: 100%;
}

.o-block__background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #eee;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}

.o-block__background:before, .o-block__background:after {
  content: '';
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.o-block__background:after {
  box-shadow: inset 10px 10px 100px rgba(33, 22, 13, 0.25);
}

.o-block__background:before {
  background-color: rgba(33, 22, 13, 0.26);
}

.o-block__backgroundLayer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  background-size: cover;
  background-position: center;
}

.o-block__backgroundLayer video {
  position: absolute;
}

.o-block__backgroundLayer--wide video {
  width: 100%;
  height: auto;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
}

.o-block__backgroundLayer--tall video {
  height: 100%;
  width: auto;
  transform: translateX(-50%);
  left: 50%;
  top: 0;
}

.o-block__heading {
  margin: 0 auto;
}

.o-block__content {
  font-size: 1.25rem;
  margin-bottom: 2.5rem;
}

.o-block--whiteText {
  color: white !important;
}

.o-block--darkBrown {
  color: white;
  background-color: #21160D;
}

.o-block__featuredImage {
  text-align: center;
}

.o-block--darkBrownMobile {
  background-color: #21160D;
}

@media screen and (min-width: 767px) {
  .o-block--darkBrownMobile {
    background-color: transparent;
  }
}

.o-block--lightBrown {
  color: white;
  background-color: #A67556;
}

.o-block--lightBrown .o-hr {
  border-color: #FFF;
}

.o-block--green {
  color: white;
  background-color: #1b4f33;
}

.o-block--fullSize .o-block__wrapper {
  max-width: 750px;
}

.o-block--paddedTop {
  padding-top: 100px;
  padding-bottom: 90px;
}

@media screen and (min-width: 767px) {
  .o-block--paddedTop {
    padding-top: 50px;
    padding-bottom: 45px;
  }
}

.o-block--quote {
  font-size: 1.25rem;
}

.o-block--quote .o-block__wrapper {
  max-width: 930px;
}

.o-block--quote .o-block__content {
  font-style: italic;
  font-family: "Playfair Display", serif;
  line-height: 2rem;
}

.o-block--quote .o-block__content:before, .o-block--quote .o-block__content:after {
  font-size: 34px;
  line-height: 1rem;
  display: inline-block;
  font-weight: bold;
}

.o-block--quote .o-block__content:before {
  content: '“';
}

.o-block--quote .o-block__content:after {
  content: '”';
}

.o-block__photoCredit {
  position: absolute;
  z-index: 3;
  right: 2rem;
  bottom: 2rem;
  text-align: right;
  font-size: 0.75rem;
  color: #A67556;
  text-transform: uppercase;
}

.o-blockquoteCredit__author {
  font-size: 1.25rem;
  margin-bottom: .5rem;
  display: block;
  font-family: "Playfair Display", serif;
}

.o-blockquoteCredit__notes {
  font-size: 0.75rem;
  line-height: 1.25rem;
  max-width: 400px;
  margin: 0 auto;
  text-transform: uppercase;
  color: #A67556;
}

.o-blockquoteCredit__logo {
  height: 60px;
  width: auto;
}

.touchevents .o-block__backgroundLayer--video {
  display: none;
}

.o-block--unpadded {
  padding: 1rem 0;
}

@media screen and (min-width: 960px) {
  .o-block__wrapper {
    max-width: 510px;
  }
  .o-block--big .o-block__wrapper, .o-block--homeHero .o-block__wrapper {
    max-width: 750px;
  }
  .o-block--full .o-block__wrapper {
    max-width: 960px;
  }
  .o-block--videoCocktail .o-block__wrapper {
    max-width: 860px;
  }
  .o-slider--cantineSlider .o-block__wrapper {
    max-width: 850px;
  }
  .o-block--unpadded {
    padding: 1rem 0;
  }
  .o-block--unpadded .o-block__content {
    padding: 0;
  }
  .o-block--unpadded .o-block__wrapper {
    max-width: 100%;
  }
  .o-block--wide .o-block__wrapper {
    max-width: initial;
  }
  .o-block--leftText .o-block__content {
    text-align: left;
  }
  .o-block--contentLeft {
    justify-content: flex-start;
  }
  .o-block--contentLeft .o-block__container {
    width: 50%;
    min-width: 400px;
  }
  .o-block--contentRight {
    justify-content: flex-end;
  }
  .o-block--contentRight .o-block__container {
    width: 50%;
    min-width: 400px;
  }
}

@media screen and (min-width: 1025px) {
  .o-block--left {
    padding-left: 0;
    padding-right: 0;
  }
  .o-block--left .o-block__wrapper {
    left: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 40%;
    position: absolute;
    z-index: 2;
    padding: 0 100px;
  }
}

@media screen and (min-width: 1200px) {
  .o-block {
    padding-left: 100px;
    padding-right: 100px;
  }
  .o-block--unpadded {
    padding-left: 0px;
    padding-right: 0px;
  }
  .o-block--left {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 1600px) {
  .o-block--left {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.o-imageBlock {
  overflow: hidden;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-grow: 2;
}

.o-imageBlock__background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.o-imageBlock--attivo .o-imageBlock__background {
  background-color: rgba(0, 0, 0, 0.26);
}

.o-imageBlock__image {
  width: 100%;
}

.o-imageBlock__photoCredit {
  position: absolute;
  z-index: 3;
  right: 2rem;
  bottom: 2rem;
  line-height: 1.4em;
  text-align: right;
  font-size: 0.75rem;
  color: #A67556;
  text-transform: uppercase;
}

.o-heading--image {
  width: 100%;
  height: auto;
}

.o-heading--white > * {
  color: white;
}

.o-heading--white .o-hr {
  border-color: white;
}

.ui-inputGroup {
  position: relative;
}

.ui-select {
  opacity: 0;
  position: absolute;
}

.ui-selectStyled__currentValue, .ui-selectStyled {
  width: 100%;
  height: 50px;
  line-height: 50px;
  display: block;
  text-transform: uppercase;
  color: #A67556;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 0.875rem;
}

.ui-selectStyled__currentValue:hover, .ui-selectStyled:hover {
  cursor: pointer;
}

.ui-selectStyled__currentValue {
  position: relative;
  z-index: 2;
  border: 1px solid #A67556;
  background-color: white;
  padding: 0 20px;
}

.ui-selectStyled__currentValue:after {
  content: url("/images/icons/arrow--down.svg");
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
}

.is-open .ui-selectStyled__currentValue {
  border-bottom: none;
}

.is-open .ui-selectStyled__currentValue:after {
  transform: translateY(calc(-50% - 1px));
}

.ui-selectStyled {
  position: absolute;
  list-style: none;
  margin: 0px;
  padding: 0px;
  bottom: 0;
}

.is-open .ui-selectStyled {
  top: 50px;
  z-index: 200;
}

.ui-selectStyled__option {
  display: none;
  background: white;
  color: #21160D;
  padding: 0 20px;
  background-color: white;
  border-left: 1px solid #A67556;
  border-right: 1px solid #A67556;
}

.ui-selectStyled__option:last-child {
  border-bottom: 1px solid #A67556;
}

.ui-selectStyled__option:hover, .ui-selectStyled__option.is-selected {
  color: white;
  background-color: #A67556;
}

.is-open .ui-selectStyled__option {
  display: block;
}

.ui-numberField {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
}

.ui-numberFieldAction {
  cursor: pointer;
  width: 15px;
  opacity: 1;
  transition: opacity .3 ease-in-out;
}

.ui-numberFieldAction:hover {
  opacity: .7;
}

.ui-numberField--current {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #A67556;
  text-align: center;
  color: #A67556;
  margin: 0 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.o-listElements {
  display: table;
  width: 100%;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.o-listElements__single {
  padding: 0.5rem 0;
  line-height: 1.375em;
}

.o-listElements--underlined .o-listElements__single {
  border-top: 1px solid #DADADA;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.o-listElements--underlined .o-listElements__single:first-child {
  border: none;
}

.o-listElements__icon, .o-listElements__text {
  display: table-cell;
  vertical-align: middle;
}

.o-listElements__icon {
  width: 60px;
}

.o-listElements__icon svg {
  width: 60px;
  height: 60px;
  display: block;
  margin: 0 auto;
}

.o-listElements__title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
}

.o-listElements__text {
  padding: 0 1rem;
  text-align: left;
}

.o-listElements__text p {
  display: inline-block;
}

.o-hr {
  display: block;
  margin: 0 auto;
  width: 52px;
  border: none;
  border-bottom: 1px solid #A67556;
  height: 0;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.o-slider {
  display: block;
  height: 100%;
  width: 100%;
}

.o-slider--cocktailSliderHome .swiper-pagination {
  display: none;
}

.js-slider--products {
  margin: 35px 0;
}

.swiper-slide {
  text-align: center;
}

.swiper-slide__image {
  width: 100%;
}

.swiper-pagination {
  position: static;
  bottom: 0px;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-pagination-bullets {
  transform-origin: 0 0;
  margin: 0 auto;
  display: block;
  padding: 10px 0;
  text-align: center;
}

.slider-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin: 10px;
  display: inline-block;
  opacity: 1 !important;
  cursor: pointer;
  position: relative;
  background-color: #A67556 !important;
}

.slider-pagination-bullet i, .slider-pagination-bullet i:after, .slider-pagination-bullet i:before {
  background-color: #A67556 !important;
}

.slider-pagination-bullet i {
  width: 0;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg) scale(0);
  animation: exit 0.75s ease 0s;
}

.slider-pagination-bullet i:before, .slider-pagination-bullet i:after {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.slider-pagination-bullet i:after {
  right: 0;
  left: auto;
}

.slider-pagination-bullet.active i {
  width: 0;
  height: 2px;
  position: absolute;
  background-color: #A67556;
  top: 50%;
  left: 50%;
  transform: translateX(-50%, -50%) rotate(45deg) scale(1);
  animation: enter 1.5s both ease-in 1;
}

.slider-pagination-bullet.active i:before, .slider-pagination-bullet.active i:after {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #A67556;
  top: 50%;
  left: 0;
  transform-origin: 50% 50%;
  transform: translate(0, -50%);
}

.slider-pagination-bullet.active i:after {
  right: 0;
  left: auto;
}

@keyframes exit {
  0% {
    transform: translateX(-50%) translateY(-50%) rotateZ(315deg) scale(1);
    width: 30px;
  }
  20% {
    width: 30px;
    transform: translateX(-50%) translateY(-50%) rotateZ(315deg) scale(1);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) rotateZ(45deg) scale(0);
    width: 30px;
  }
}

@keyframes enter {
  0% {
    transform: translateX(-50%) translateY(-50%) rotateZ(45deg) scale(0.1);
    width: 30px;
  }
  20% {
    width: 30px;
    transform: translateX(-50%) translateY(-50%) rotateZ(45deg) scale(1);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) rotateZ(315deg) scale(1);
    width: 30px;
  }
}

.slider-navigation-wrapper {
  position: relative;
  bottom: 0px;
  width: 100%;
  height: 50px;
  z-index: 10;
  text-align: center;
}

@media screen and (min-width: 767px) {
  .slider-navigation-wrapper {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 100px;
  }
}

.slider-navigation-button {
  display: inline-block;
  background-color: #A67556;
  cursor: pointer;
  position: relative;
  width: 50px;
  height: 100%;
  margin: 0px -1px;
  transition: all .3s ease-in-out;
}

@media screen and (min-width: 767px) {
  .slider-navigation-button {
    display: block;
    width: 100%;
    height: 0px;
    padding-bottom: 100%;
    margin: 0px;
  }
}

.o-slider--white .slider-navigation-button {
  background-color: #A67556;
  border-bottom: 1px solid #FFF;
}

.o-slider--lightBrown .slider-navigation-button {
  background-color: #FFF;
  border-bottom: #A67556;
}

.slider-navigation-button:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 11px;
  height: 19px;
}

.slider-navigation-button:hover {
  background-color: white;
}

@media screen and (min-width: 767px) {
  .slider-button-prev {
    margin-bottom: 1px;
  }
}

.slider-button-prev:after {
  content: url(/images/icons/arrow--left__white.svg);
}

.slider-button-prev:hover:after {
  content: url(/images/icons/arrow--left.svg);
}

.slider-button-next:after {
  content: url(/images/icons/arrow--right__white.svg);
}

.slider-button-next:hover:after {
  content: url(/images/icons/arrow--right.svg);
}

.o-slider--whiteBullets .slider-pagination-bullet {
  background-color: white !important;
}

.o-slider--whiteBullets .slider-pagination-bullet i, .o-slider--whiteBullets .slider-pagination-bullet i:after, .o-slider--whiteBullets .slider-pagination-bullet i:before {
  background-color: white !important;
}

.o-slider--cantineSlider {
  display: none;
  max-width: 1680px;
  margin: 0 auto;
}

@media screen and (min-width: 767px) {
  .o-slider--cantineSlider {
    display: block;
  }
}

.o-slider--homeHeroSlider .swiper-pagination {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: 0;
  top: 0;
  bottom: 0;
  left: auto;
  position: absolute;
  width: 40px;
}

.overlayBox {
  display: none;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #FFF;
  z-index: 10;
}

.overlayBox.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlayBox__close {
  cursor: pointer;
  height: 50px;
  width: 50px;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  background-color: #A67556;
  transition: all .3s ease-in-out;
}

.overlayBox__close:after {
  content: url(/images/icons/icon--close__white.svg);
  position: absolute;
  width: 16px;
  height: 19px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlayBox__close:hover {
  background-color: white;
}

.overlayBox__close:hover:after {
  content: url(/images/icons/icon--close.svg);
}

.overlayBox__content {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.overlayBox__loading {
  background-image: url("/images/icons/preloader.gif");
  background-position: center;
  background-repeat: no-repeat;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.tabsWrapper {
  width: 100%;
  max-width: 690px;
  margin: 60px auto 30px;
}

.ui-tabs {
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  margin: 0 auto;
  list-style: none;
  padding: 0px;
  text-align: center;
}

.ui-tabs .ui-tabs__single {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid #A67556;
  border-bottom: 1px solid #A67556;
  background: none;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 0.9375rem;
  line-height: 1.5;
  padding: 0.85rem;
  color: #A67556;
  text-align: center;
  min-width: 200px;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  transition: all .3s ease-in-out;
}

.ui-tabs .ui-tabs__single.is-active, .ui-tabs .ui-tabs__single:hover, .ui-tabs .ui-tabs__single:active {
  color: white;
  background-color: #A67556;
}

.ui-tabs .ui-tabs__single:hover {
  transition: all .3s .3s ease-in-out;
}

.ui-tabs .ui-tabs__single:hover i:before, .ui-tabs .ui-tabs__single:hover i:after {
  transition: all .3s ease-in-out;
}

.ui-tabs .ui-tabs__single:hover i:before {
  bottom: 50%;
}

.ui-tabs .ui-tabs__single:hover i:after {
  top: 50%;
}

.ui-tabs .ui-tabs__single i:before, .ui-tabs .ui-tabs__single i:after {
  content: '';
  border: 1px solid #A67556;
  border-top: none;
  border-bottom: none;
  position: absolute;
  left: 0;
  right: 0;
  transition: all .3s ease-in-out;
}

.ui-tabs .ui-tabs__single i:before {
  top: 0;
  bottom: 100%;
}

.ui-tabs .ui-tabs__single i:after {
  top: 100%;
  bottom: 0;
}

.ui-filters {
  position: relative;
  z-index: 2;
  width: 100%;
  display: block;
  margin: 0 auto;
  list-style: none;
  padding: 0px;
  text-align: center;
}

.ui-filters__single {
  cursor: pointer;
  display: inline-block;
  padding: 0 15px;
  position: relative;
  transition: color .3s 0s ease-in-out;
  text-transform: uppercase;
  font-size: 0.875rem;
}

.ui-filters__single:after {
  content: "•";
  line-height: 1;
  position: absolute;
  right: -7.5px;
  top: 40%;
  transform: translate3d(-50%, -50%, 0);
  color: #21160D;
}

.ui-filters__single.is-selected, .ui-filters__single:hover, .ui-filters__single:active {
  color: #A67556;
}

.ui-filters__single:last-child:after {
  content: "";
}

.o-socials {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 0;
  display: flex;
  justify-content: center;
}

.o-socials li {
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 0;
  margin: 0 12px;
}

.o-socials li:last-child {
  width: 22px;
  margin-left: 10px;
  margin-right: 0;
}

.o-social {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.o-social--yt {
  width: 25px;
}

.o-social svg {
  width: 100%;
  height: 100%;
}

.o-socials--grey .o-social {
  color: #dadada;
}

.o-socials--grey .o-social svg > * {
  fill: #dadada;
}

.o-socials--grey .o-social:hover svg > * {
  fill: #A67556;
}

.o-social--link {
  position: relative;
  cursor: pointer;
}

.clipBoardBalloon {
  cursor: default;
  opacity: 0;
  background: #b2b2b2;
  position: absolute;
  top: -33px;
  left: 50%;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 10px;
  transform: translateX(-50%);
  transition: opacity .3s ease-in-out 0s;
}

.clipBoardBalloon i {
  position: absolute;
  left: 50%;
  bottom: -8px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 5px 0 5px;
  border-color: #b2b2b2 transparent transparent transparent;
}

.clipBoardBalloon span {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 0.7rem;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
}

.is-copied .clipBoardBalloon {
  opacity: 1;
}

.c-dotSeparator {
  transform: translateY(-0.13em);
  display: inline-block;
}

.loading {
  position: fixed;
  z-index: 400;
  background-color: #21160D;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  mask: url("../images/sprite.png") no-repeat 0 0;
  mask-size: auto 1600%;
  mask-position: center 0%;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
}

@media screen and (min-width: 1200px) {
  .loading {
    mask-size: 100% 1600%;
  }
}

.loading__wrapper {
  position: absolute;
  width: 100%;
  max-width: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading__gif {
  display: block;
  margin: 0 auto 2rem;
  width: 100px;
}

.animateOut {
  animation: mask 0.8s steps(15) forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mask {
  0% {
    mask-position: center 0%;
  }
  60% {
    opacity: 1;
  }
  100% {
    mask-position: center 100%;
    opacity: 0;
  }
}

.hero {
  color: white;
  background-color: #21160D;
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
  text-align: center;
  max-width: 1680px;
  margin: 0 auto;
  height: 100vh;
}

.hero__container {
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;
  position: absolute;
  z-index: 2;
  max-width: 400px;
  margin-left: 6.25rem;
}

.hero__content {
  font-size: 1.25rem;
}

.hero__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.hero__backgroundHero {
  width: 100%;
}

.hero__imageHero {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.hero__buyWrapper {
  width: 375px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.o-siteHeader {
  display: block;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 200;
  transition: transform .4s ease, background-color .3s ease;
}

.o-siteHeader.is-unpinned {
  transform: translateY(-100%);
}

.o-siteHeader.is-pinned {
  transform: translateY(0);
}

.o-siteHeader.is-not-top {
  background-color: rgba(33, 22, 13, 0.5);
}

.o-siteHeader__wrapper {
  display: flex;
}

.o-siteHeader__menuToggle,
.o-siteHeader__logo,
.o-siteHeader__iconsWrapper {
  font-size: 0.9375rem;
  display: inline-block;
  width: 100%;
  color: white;
  border: none;
  height: 70px;
}

.o-siteHeader__logo {
  background-image: url(/images/logo.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 55px auto;
  background-color: #A67556;
}

.o-siteHeader__logo svg {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 53px;
}

.o-siteHeader__logo svg path {
  fill: white;
}

.o-siteHeader__iconsWrapper {
  font-weight: 500;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  position: relative;
  z-index: 10;
  padding-right: 1.5rem;
}

.o-siteHeader__icon {
  cursor: pointer;
  margin: 0 0 0 30px;
  height: 28px;
  width: 28px;
}

.o-siteHeader__icon svg {
  height: 100%;
  width: 100%;
}

.o-siteHeader__textLink,
.o-siteHeader__icon,
.o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher {
  display: none;
}

@media screen and (min-width: 767px) {
  .o-siteHeader__textLink,
  .o-siteHeader__icon,
  .o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher {
    display: inline-block;
  }
}

.o-siteHeader__icon--basket {
  margin-right: 10px;
}

.o-siteHeader__icon--basket svg {
  height: 85%;
}

.o-siteHeader__icon--labeled {
  position: relative;
}

.o-siteHeader__iconLabel {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  background: #A67556;
  text-align: center;
  color: white;
  font-size: 0.75rem;
  border-radius: 50%;
  font-style: normal;
}

.o-siteHeader__menuToggle,
.o-siteHeader__logoWrapper {
  position: relative;
  z-index: 10;
  text-align: center;
  flex: 100px 0 0;
  cursor: pointer;
  background-color: #A67556;
}

@media screen and (min-width: 767px) {
  .o-siteHeader__menuToggle,
  .o-siteHeader__logoWrapper {
    flex: 105px 0 0;
  }
}

.o-siteHeader__menuToggle:hover, .o-siteHeader__menuToggle:active, .o-siteHeader__menuToggle.is-active,
.o-siteHeader__logoWrapper:hover,
.o-siteHeader__logoWrapper:active,
.o-siteHeader__logoWrapper.is-active {
  color: #A67556;
  background: white;
}

.o-siteHeader__logoWrapper {
  line-height: 0px;
}

.o-siteHeader__menuToggle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 0.9375rem;
}

.o-siteHeader__menuToggleText--close {
  display: none;
}

.o-siteHeader__languageSwitcher {
  display: flex;
  height: 100%;
  width: 70px;
  margin-right: 1rem;
  position: relative;
}

.o-siteHeader__languageSwitcher__wrapper {
  height: 100%;
  transform: translate3d(0, 0, 0);
  transition: transform .3s ease;
}

.o-siteHeader__languageSwitcher__arrow {
  position: absolute;
  top: 50%;
  right: 7px;
  z-index: 10;
  transform: translateY(-50%);
}

.o-siteHeader__languageSwitcher.is-open .o-siteHeader__languageSwitcher__arrow {
  transform: rotate(180deg) translateY(-50%);
  transform-origin: 50% 0%;
}

.o-siteHeader__languageSwitcher__list {
  background-color: #A67556;
  width: 90px;
  color: white;
  opacity: 0;
  visibility: hidden;
  padding: 0px;
  margin: 0 0 0 -10px;
  list-style: none;
}

.o-siteHeader__languageSwitcher.is-open .o-siteHeader__languageSwitcher__list {
  opacity: 1;
  visibility: visible;
}

.o-siteHeader__languageSwitcher__lang, .o-siteHeader__languageSwitcher__currentlang {
  text-transform: uppercase;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 100% 0 0;
  text-align: center;
  cursor: pointer;
}

.o-siteHeader__languageSwitcher__lang {
  background-color: #A67556;
  color: white;
  height: 35px;
}

.o-siteHeader__languageSwitcher__lang:hover {
  background-color: white;
  color: #A67556;
}

.o-siteHeader.is-open {
  bottom: 0px;
  overflow: hidden;
}

.o-siteHeader.is-open .o-siteHeader__iconsWrapper {
  background-color: #A67556;
}

@media screen and (min-width: 767px) {
  .o-siteHeader.is-open .o-siteHeader__iconsWrapper {
    background-color: transparent;
  }
}

.o-siteHeader.is-open .o-siteHeader__menuToggleText--open {
  display: none;
}

.o-siteHeader.is-open .o-siteHeader__menuToggleText--close {
  display: block;
}

.o-siteHeader .o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher__currentlang {
  color: white;
}

.o-siteHeader .o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher__currentlang svg path {
  fill: white;
}

.o-siteHeader .o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher__currentlang:hover {
  color: white;
}

.o-siteHeader .o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher__currentlang:hover svg path {
  fill: white;
}

.o-siteHeader .o-siteHeader__iconsWrapper .o-siteHeader__textLink {
  color: white;
}

.o-siteHeader .o-siteHeader__iconsWrapper .o-siteHeader__textLink:hover {
  color: white;
}

.o-siteHeader .o-siteHeader__iconsWrapper .o-siteHeader__icon svg path {
  fill: white;
}

.o-siteHeader .o-siteHeader__iconsWrapper .o-siteHeader__icon:hover svg path {
  fill: white;
}

.o-siteHeader.bordelloDiUnCastello .o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher__currentlang {
  color: #A67556;
}

.o-siteHeader.bordelloDiUnCastello .o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher__currentlang svg path {
  fill: #A67556;
}

.o-siteHeader.bordelloDiUnCastello .o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher__currentlang:hover {
  color: white;
}

.o-siteHeader.bordelloDiUnCastello .o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher__currentlang:hover svg path {
  fill: white;
}

.o-siteHeader.bordelloDiUnCastello .o-siteHeader__iconsWrapper .o-siteHeader__textLink {
  color: #A67556;
}

.o-siteHeader.bordelloDiUnCastello .o-siteHeader__iconsWrapper .o-siteHeader__textLink:hover {
  color: white;
}

.o-siteHeader.bordelloDiUnCastello .o-siteHeader__iconsWrapper .o-siteHeader__icon svg path {
  fill: #A67556;
}

.o-siteHeader.bordelloDiUnCastello .o-siteHeader__iconsWrapper .o-siteHeader__icon:hover svg path {
  fill: white;
}

.o-siteHeader.is-not-top .o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher__currentlang {
  color: white;
}

.o-siteHeader.is-not-top .o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher__currentlang svg path {
  fill: white;
}

.o-siteHeader.is-not-top .o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher__currentlang:hover {
  color: white;
}

.o-siteHeader.is-not-top .o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher__currentlang:hover svg path {
  fill: white;
}

.o-siteHeader.is-not-top .o-siteHeader__iconsWrapper .o-siteHeader__textLink {
  color: white;
}

.o-siteHeader.is-not-top .o-siteHeader__iconsWrapper .o-siteHeader__textLink:hover {
  color: white;
}

.o-siteHeader.is-not-top .o-siteHeader__iconsWrapper .o-siteHeader__icon svg path {
  fill: white;
}

.o-siteHeader.is-not-top .o-siteHeader__iconsWrapper .o-siteHeader__icon:hover svg path {
  fill: white;
}

.o-siteHeader.is-open .o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher__currentlang {
  color: #A67556;
}

.o-siteHeader.is-open .o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher__currentlang svg path {
  fill: #A67556;
}

.o-siteHeader.is-open .o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher__currentlang:hover {
  color: #A67556;
}

.o-siteHeader.is-open .o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher__currentlang:hover svg path {
  fill: #A67556;
}

.o-siteHeader.is-open .o-siteHeader__iconsWrapper .o-siteHeader__textLink {
  color: #A67556;
}

.o-siteHeader.is-open .o-siteHeader__iconsWrapper .o-siteHeader__textLink:hover {
  color: #A67556;
}

.o-siteHeader.is-open .o-siteHeader__iconsWrapper .o-siteHeader__icon svg path {
  fill: #A67556;
}

.o-siteHeader.is-open .o-siteHeader__iconsWrapper .o-siteHeader__icon:hover svg path {
  fill: #A67556;
}

.o-siteMenu {
  display: none;
  height: 100%;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-overflow-scrolling: touch;
  z-index: -1;
}

.is-open .o-siteMenu {
  display: block;
  overflow-y: scroll;
}

@media screen and (min-width: 767px) {
  .o-siteMenu {
    min-height: 100vh;
  }
  .is-open .o-siteMenu {
    display: flex;
  }
}

.o-siteMenu__single {
  background-color: white;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex: 50% 1 0;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  padding: 2rem 4rem 2rem;
  transition: top cubic-bezier(1, 0, 0, 1) 1s;
  position: relative;
}

.o-siteMenu__single.is-open {
  top: 140px;
}

@media screen and (min-width: 767px) {
  .o-siteMenu__single {
    padding: 5rem 1rem;
  }
}

.o-siteMenu__single--primary {
  padding-bottom: 1.5rem;
  background-color: #21160D;
}

@media screen and (min-width: 767px) {
  .o-siteMenu__single--primary {
    padding-top: 2.5rem;
    padding-bottom: 5rem;
  }
}

.o-siteMenu__single--products {
  padding-top: 1.2rem;
}

.o-siteMenu__single--products .o-menu__item {
  position: relative;
}

.o-siteMenu__single--products .o-menu__item:after {
  display: inline-block;
  position: absolute;
  content: "";
  border-bottom: 1px solid #A67556;
  width: 50px;
  height: 1px;
  left: 50%;
  top: -5px;
  transform: translateX(-50%);
}

.o-siteMenu__single--products .o-menu__item:first-child:after {
  display: none;
}

.o-siteMenu__single--products .o-menu__singleItem {
  text-transform: none;
  color: #21160D;
  font-family: "Playfair Display", serif;
  font-weight: bold;
  font-size: 1.5625rem;
  line-height: 1.1em;
}

.o-siteMenu__single--products .o-menu__singleItem-description {
  font-size: .875rem;
  color: #A67556;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1em;
}

@media screen and (min-width: 600px) {
  .o-siteMenu {
    flex-direction: row;
  }
  .o-siteMenu__single {
    display: flex;
    flex: 50% 1 0;
  }
}

.o-menu__listItem {
  display: table-cell;
  vertical-align: middle;
}

.o-menu__listItem, .o-menu__sublistItem {
  margin: 0px;
  padding: 0px;
  text-align: center;
  list-style: none;
  text-transform: uppercase;
}

.o-menu__item {
  padding: 1rem 0;
  margin: 0px;
}

@media screen and (min-width: 767px) {
  .o-menu__item {
    margin: 8px 0;
  }
}

.o-menu__singleItem, .o-menu__subsingleItem {
  display: block;
  font-size: 1.125rem;
  color: #A67556;
}

.o-menu__sublistItem {
  margin: 0;
  height: 0px;
  overflow: hidden;
}

.o-menu__sublistItem.is-open {
  margin: 11px 0;
  height: auto;
}

.o-menu__subsingleItem {
  padding: 5px 0;
  opacity: 0.5;
  font-size: 0.875rem;
}

.o-menu__single--productsMenu {
  font-family: "Playfair Display", serif;
}

.o-menu__single--productsMenu .o-menu__singleItem, .o-menu__single--productsMenu .o-menu__subsingleItem {
  text-transform: none;
  font-size: 1.5625rem;
  font-weight: bold;
  color: #21160D;
}

.o-menu__single--productsMenu .o-menu__singleItem:after {
  content: '';
  display: block;
  margin: 0 auto;
  width: 52px;
  border: none;
  border-bottom: 1px solid #A67556;
  height: 0;
  margin-top: .75rem;
}

.o-siteMenu__iconsMobile {
  display: flex;
  position: relative;
  top: 70px;
  -webkit-box-align: center;
  align-items: center;
  background: white;
  color: #A67556;
  padding: 0 35px;
  height: 70px;
  font-size: 1.125rem;
  justify-content: space-between;
}

.o-siteMenu__iconsMobile .languageSwitcher {
  display: flex;
  margin-right: 2.5rem;
  text-transform: uppercase;
}

.o-siteMenu__iconsMobile .languageSwitcher__dropdown {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  position: absolute;
  margin: 0;
  padding: 0 35px;
  width: 100%;
  height: 70px;
  left: 0;
  top: 70px;
  right: 0;
  z-index: -1;
  background-color: #fff;
  list-style: none;
}

@media screen and (min-width: 767px) {
  .o-siteMenu__iconsMobile {
    display: none;
  }
}

.o-siteMenu__icon--search {
  display: inline-block;
  width: 20px;
  margin-left: 2.5rem;
  height: 30px;
  position: relative;
  vertical-align: middle;
  height: 100%;
}

.o-siteMenu__icon--search svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 766px) {
  .o-siteMenu__single {
    top: 70px;
  }
}

.main {
  opacity: 1;
}

html.is-loading .main {
  opacity: 0;
}

.page {
  text-align: left;
  display: block;
  margin: 0 auto;
  padding: 100px 15px;
  width: 100%;
  max-width: 1000px;
}

.page h1 {
  text-align: center;
}

#products, #storia {
  padding-top: 70px;
}

@media screen and (min-width: 767px) {
  #products, #storia {
    padding-top: 0px;
  }
}

.ageCheck {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: #21160D;
  text-align: center;
  z-index: 399;
  display: flex;
  align-items: center;
  padding-top: 50px;
  flex-direction: column;
}

@media screen and (min-width: 767px) {
  .ageCheck {
    padding-top: 0px;
    justify-content: center;
  }
}

.ageCheck__wrapper {
  width: 100%;
  max-width: 500px;
  justify-self: center;
}

.ageCheck__logo {
  display: block;
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
}

.ageCheck__logo svg {
  display: block;
}

.ageCheck__title {
  color: white;
  font-size: 1.5625rem;
  font-family: "Playfair Display", serif;
  font-weight: 600;
  line-height: 1.26em;
  margin: 43px 0;
}

.ageCheck__inputs {
  display: flex;
  justify-content: center;
}

.ageCheck__inputs > .ui-button {
  min-width: auto;
  width: 100%;
  height: 70px;
  max-width: 115px;
  margin-right: 0.5rem;
  margin-bottom: 0px;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 0.9375rem;
}

.ageCheck__inputs > .ui-button .ui-button__inner {
  padding: 0px;
  line-height: 70px;
}

.ageCheck__inputs > .ui-button:last-child {
  margin-left: 0.5rem;
  margin-right: 0;
}

.ageCheck__inputs .ui-button.is-focus {
  background: transparent;
}

.ageCheck__inputs .ui-button.is-focus input {
  color: white;
}

.ageCheck__errors {
  display: none;
  margin-bottom: 1rem;
  color: #d83d3d;
}

.ageCheck__remember {
  font-size: 14px;
  text-transform: uppercase;
  color: #b2b2b2;
  display: block;
  text-align: left;
  margin: 30px 0;
}

.ageCheck__remember input {
  margin-right: 1rem;
}

.ageCheck__disclaimer {
  position: static;
  margin-top: 50px;
  color: #b2b2b2;
  width: 100%;
  max-width: 1000px;
  padding: 0 1rem;
  font-size: 0.875rem;
  line-height: 1.3em;
}

@media screen and (min-width: 767px) {
  .ageCheck__disclaimer {
    padding: 0px;
    margin-top: 0px;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.o-slider--cocktailSliderHome .o-block {
  padding: 4rem 2rem 2rem;
}

.cocktailsSlider {
  display: none;
  height: 100vh;
  position: relative;
  max-width: 1680px;
  margin: 0 auto;
}

.cocktailsSlider-wrapper {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.cocktailsSlider-navigation {
  position: absolute;
  bottom: 0;
  top: auto;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  z-index: 60;
  height: 60px;
  display: block;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (min-width: 767px) {
  .cocktailsSlider-navigation {
    display: flex;
    top: 0px;
    bottom: auto;
    height: 100%;
  }
}

.cocktailsSlider-navigation__arrow {
  background-color: #A67556;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  cursor: pointer;
}

.cocktailsSlider-navigation__arrow svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 10px;
  transform: rotate(-90deg);
  margin-top: -5px;
  margin-left: -10px;
}

.cocktailsSlider-navigation__arrow svg path {
  fill: white;
}

@media screen and (min-width: 767px) {
  .cocktailsSlider-navigation__arrow {
    background-color: white;
  }
  .cocktailsSlider-navigation__arrow svg {
    transform: rotate(0deg);
  }
  .cocktailsSlider-navigation__arrow svg path {
    fill: #A67556;
  }
}

.cocktailSlide {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: none;
}

.cocktailSlide.is-active {
  display: flex;
  z-index: 4;
}

.cocktailSlide-index {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 60px;
  height: 60px;
  z-index: 20;
}

.cocktailSlide-half {
  flex: 100%;
  background-color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  background-size: auto 100%;
  background-position: center 100%;
}

.cocktailSlide-half__wrapper {
  max-width: 480px;
  margin: 0 auto;
  width: 90%;
}

.cocktailSlide-half--grappa {
  color: white;
  display: none;
}

.cocktailSlide-cocktail__text {
  display: none;
  font-size: 20px;
  line-height: 1.5em;
  margin-bottom: 2rem;
}

@media screen and (min-width: 767px) {
  .cocktailSlide-cocktail__text {
    display: block;
  }
}

.cocktailSlide-cocktail__cta {
  margin-bottom: 2rem;
}

.cocktailSlide-grappa {
  color: white;
}

.cocktailSlide-cocktail__picture {
  display: block;
  width: 70%;
  margin: 2rem auto;
}

.cocktailSlide-cocktail__picture img {
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .cocktailSlide-cocktail__picture {
    margin: 0 auto;
  }
}

.cocktailSlide-cocktail__pictureMobile {
  display: block;
  width: 100%;
  margin: 2rem auto;
}

.cocktailSlide-cocktail__pictureMobile img {
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .cocktailSlide-cocktail__pictureMobile {
    margin: 0 auto;
  }
}

@media screen and (min-width: 414px) {
  .cocktailSlide-half {
    padding-top: 5rem;
  }
}

@media screen and (min-width: 1025px) {
  .cocktailsSlider {
    display: block;
  }
  .o-slider--cocktailSliderHome {
    display: none;
  }
  .cocktailsSlide-half {
    flex: 50% 0 0;
  }
  .cocktailSlide-half--grappa {
    display: flex;
  }
}

.home-hero__note {
  display: none;
  position: absolute;
  height: auto;
  bottom: 1rem;
  left: 2rem;
  z-index: 1;
  color: white;
  text-transform: uppercase;
}

.home-hero__note--right {
  left: auto;
  right: 2rem;
  max-width: 250px;
  text-align: right;
  line-height: 1.2;
}

@media screen and (min-width: 960px) {
  .home-hero__note--right {
    max-width: 100%;
    line-height: inherit;
  }
}

.home-hero__note--right img {
  display: inline-block;
  height: 10px;
  margin-right: 10px;
}

@media screen and (min-width: 767px) {
  .home-hero__note {
    display: block;
  }
}

.home-hero__marchioDistillazione {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 1;
  height: auto;
  transform: translateX(-50%);
}

.home-hero__marchioDistillazione img {
  display: block;
  width: 130px;
}

.touchevents video {
  display: none;
}

.productsSlider-slide {
  width: 80%;
  max-width: 360px;
  padding: 1rem;
  display: block;
}

.productSlider-slide__heading {
  display: flex;
  height: 200px;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  opacity: 0;
  position: absolute;
  top: -2rem;
  left: 0;
  right: 0;
  padding: 0 1rem;
  transition: all .3s ease-in-out;
}

.productsSlider-slide:hover .productSlider-slide__heading, .productsSlider-slide.is-active .productSlider-slide__heading {
  opacity: 1;
  top: 0px;
}

.productSlider-slide__image {
  display: block;
}

.productSlider-slide__image img {
  width: 100%;
}

/**
 * Stili per aggregato prodotti
 */
/** 
 *
 * Stili per la pagina prodotto
 * 
 */
/**
 * Mixin specifici per Product, non condivisi nel resto del sito
 */
.product-hero {
  color: white;
  background-color: #21160D;
  text-align: center;
  width: 100%;
  overflow: hidden;
}

.product-hero.is-fixed {
  position: fixed;
}

.product-hero__background {
  position: relative;
  display: block;
  width: 100%;
  height: 450px;
  transform: rotate3d(360deg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (min-width: 820px) {
  .product-hero__background {
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-bottom: 2rem;
    height: auto;
    padding-top: 100vh;
  }
}

.product-hero__buyWrapper {
  left: 0;
  right: 0;
  z-index: 100;
}

@media screen and (min-width: 767px) {
  .product-hero__buyWrapper {
    position: absolute;
    bottom: -1px;
  }
}

.product-hero__content {
  padding: 0 20px;
  margin: 0 auto;
  margin-bottom: 3rem;
  margin-top: 2rem;
  font-size: 1.25rem;
  width: 100%;
}

@media screen and (min-width: 767px) {
  .product-hero__content {
    margin-bottom: 4rem;
  }
}

.product-hero__content .o-socials {
  display: none;
  margin-top: 1rem;
}

.product-hero__content .o-socials svg > path {
  fill: white;
}

@media screen and (min-width: 767px) {
  .product-hero__content .o-socials {
    display: block;
  }
}

.product-hero__container {
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media screen and (min-width: 960px) {
  .product-hero__container {
    padding: 0px;
  }
}

@media screen and (min-width: 767px) {
  .product-hero__container {
    padding-bottom: 2rem;
  }
}

.product-hero__heading * {
  color: white;
}

.product-hero__info {
  margin: 0 auto;
  height: 70px;
}

.product-hero__info img {
  height: 100%;
  display: inline-block;
  margin: 0 5px;
}

.product-wrapper {
  width: 100% !important;
}

@media screen and (min-width: 500px) {
  .product-hero__content {
    text-align: center;
  }
}

@media screen and (min-width: 1025px) {
  .product-hero {
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    text-align: center;
    margin: 0 auto;
  }
  .product-hero__container {
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    position: absolute;
    z-index: 2;
    padding: 0 100px;
  }
  .product-hero__background {
    width: 100%;
  }
  .product-hero__background:before, .product-hero__background:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(33, 22, 13, 0.05);
  }
  .product-hero__background:after {
    left: inherit;
    right: 0;
    background: linear-gradient(to left, #21160d 0%, rgba(33, 22, 13, 0) 100%);
  }
  .product-hero__content {
    text-align: center;
  }
  .product-hero__background {
    margin-bottom: 0;
  }
  .product-hero__buyWrapper {
    width: 375px;
    position: absolute;
    right: 0;
    bottom: -1px;
    left: auto;
    z-index: 2;
  }
  .product-hero__info {
    position: absolute;
    bottom: -1px;
    width: 40%;
  }
}

.o-cartWrapper {
  display: none;
  background-color: white;
  padding: 30px;
  text-align: left;
}

.o-cartWrapper__header {
  text-align: left;
}

.o-cartWrapper__label, .o-cartWrapper__label$col-dark {
  line-height: 1;
  margin-bottom: 20px;
}

.o-cartWrapper__label$col-dark {
  color: #21160D;
}

.o-cartWrapper__capacitaList {
  display: flex;
}

.o-cartWrapper__quantita {
  margin: 40px 0 170px;
  display: flex;
  justify-content: space-between;
}

.o-cartWrapper__quantita > * {
  display: inline-block;
  position: relative;
  vertical-align: top;
  line-height: 50px;
}

.product-features {
  position: relative;
  overflow: hidden;
}

.product-features__background {
  position: relative;
  display: block;
  width: 100%;
  height: 450px;
  transform: rotate3d(360deg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
}

@media screen and (min-width: 820px) {
  .product-features__background {
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-bottom: 2rem;
    height: auto;
    padding-top: 100vh;
  }
}

.product-features__columnFeatures {
  margin: 0 1rem 4.5rem;
  background-color: white;
}

@media screen and (min-width: 767px) {
  .product-features__columnFeatures {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1025px) {
  .product-features {
    position: relative;
    display: flex;
    margin: 0 auto;
    max-width: 1680px;
    width: 100%;
  }
  .product-features__wrapper {
    display: flex;
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    margin: 0px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
  }
  .product-features__wrapper > * {
    padding: 0;
  }
  .product-features__columnScheda, .product-features__columnFeatures {
    display: flex;
  }
  .product-features__columnScheda {
    width: 40%;
  }
  .product-features__productImage {
    margin: 0 auto;
  }
  .product-features__columnFeatures {
    background-color: rgba(255, 255, 255, 0.5);
    position: relative;
    left: inherit;
    justify-content: center;
    align-items: center;
    flex: 375px 0 0;
  }
  .product-features__columnFeatures:before {
    content: '';
    display: none;
  }
}

.is-unpinned {
  background-attachment: inherit;
}

.product-confezione__content {
  margin-bottom: 1.5rem;
}

.product-abbinamentiImages {
  margin: 0px;
  padding: 0px;
  display: block;
  width: 100%;
  text-align: center;
  list-style: none;
  justify-content: center;
}

@media screen and (min-width: 767px) {
  .product-abbinamentiImages {
    display: flex;
  }
}

.product-abbinamentiImages__single {
  vertical-align: middle;
  margin: 0;
  display: inline-block;
  width: 50%;
}

@media screen and (min-width: 767px) {
  .product-abbinamentiImages__single {
    width: 200px;
  }
}

.product-abbinamentiImages__single img {
  width: 100%;
  display: block;
}

#prodotti .o-grid__item {
  display: none;
}

#prodotti .o-grid__item.is-visible {
  display: inline-block;
}

#prodotti .o-grid__item .cocktailCard__image img {
  max-width: 300px;
}

.product-hero, .product-features {
  min-height: 100vh;
}

.product-hero {
  z-index: 10;
}

.product-hero__contentWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1680px;
  display: block;
  margin: 0 auto;
}

.product-materiaPrima {
  color: white;
  position: absolute;
  right: 0;
  left: 50%;
  bottom: 0;
  top: 50%;
  z-index: 20;
  color: white;
  text-align: center;
}

.product-materiaPrima__wrapper {
  display: none;
  text-align: left;
  position: relative;
  width: 220px;
}

@media screen and (min-width: 1281px) {
  .product-materiaPrima__wrapper {
    display: inline-block;
  }
}

.product-materiaPrima__arrow {
  height: 174px;
  width: 40px;
  position: absolute;
  top: 50%;
  left: -130px;
  border-left: 1px solid white;
}

.frut .product-materiaPrima__arrow {
  height: 94px;
  left: -190px;
}

.ue-lacquavite-duva .product-materiaPrima__arrow {
  height: 54px;
  left: -150px;
}

.gioiello .product-materiaPrima__arrow {
  height: 54px;
  left: -210px;
}

.product-materiaPrima__arrow:before, .product-materiaPrima__arrow:after {
  content: '';
  position: absolute;
  background-color: white;
  top: 50%;
}

.product-materiaPrima__arrow:before {
  top: 0;
  width: 110px;
  height: 1px;
}

.frut .product-materiaPrima__arrow:before {
  width: 170px;
}

.ue-lacquavite-duva .product-materiaPrima__arrow:before {
  width: 130px;
}

.gioiello .product-materiaPrima__arrow:before {
  width: 190px;
}

.product-materiaPrima__arrow:after {
  top: 180px;
  left: -4px;
  width: 7px;
  height: 7px;
  border-radius: 6px;
}

.frut .product-materiaPrima__arrow:after {
  top: 100px;
}

.ue-lacquavite-duva .product-materiaPrima__arrow:after {
  top: 60px;
}

.gioiello .product-materiaPrima__arrow:after {
  top: 60px;
}

.product-materiaPrima__icon {
  position: absolute;
  bottom: -37px;
  left: -64px;
  width: 54px;
  height: 54px;
}

.product-materiaPrima__icon svg * {
  fill: white;
}

.product-materiaPrima__title {
  margin: 0;
  font-weight: 500;
  font-family: "Playfair Display", serif;
  font-size: 1.25rem;
}

.product-materiaPrima__text {
  font-size: 1.25rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.product-cocktails {
  display: block;
  margin: 0 auto;
  max-width: 1680px;
  width: 100%;
}

.product-cocktails .slider-navigation-wrapper {
  display: none;
}

@media screen and (min-width: 767px) {
  .product-cocktails .slider-navigation-wrapper {
    display: inline-block;
  }
}

/**
 * @todo: splittare in diversi file
 */
.metodo-hero {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #21160D;
  min-height: 500px;
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/images/metodo/metodo-mobile.jpg");
  background-position: center;
  background-size: cover;
}

@media screen and (min-width: 1025px) {
  .metodo-hero {
    background-image: none;
    height: 100vh;
  }
}

.metodo-hero__block {
  text-align: center;
  width: 90%;
  max-width: 532px;
  position: absolute;
  z-index: 5;
  top: calc(70px + 2rem);
  left: 50%;
  transform: translateX(-50%);
  color: white;
}

@media screen and (min-width: 767px) {
  .metodo-hero__block {
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.metodo-hero__blockContent {
  font-size: 1.25rem;
  line-height: 1.5em;
}

.metodo-hero__cta {
  margin-top: 1rem;
}

.metodo-hero__video {
  position: absolute;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

.metodo-hero__video video {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.metodo-hero__points {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.metodo-hero__pointsGroup {
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  transition: all .3s ease;
}

.metodo-hero__pointsGroup.is-active {
  opacity: 1;
  transition-delay: .3s;
  z-index: 10;
}

.metodo-hero__point {
  position: absolute;
  z-index: 5;
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #21160D;
  cursor: pointer;
  transition: all .3s ease;
}

.metodo-hero__point:hover {
  transform: scale(1.2);
}

.metodo-hero__controlBar {
  position: absolute;
  width: 100%;
  height: 52px;
  display: flex;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
}

.metodo-hero__controls {
  display: flex;
  flex: 100px 0 0;
  background-color: white;
  justify-content: center;
  align-items: center;
}

.metodo-hero__timeline {
  display: flex;
  flex: 100% 1 1;
  position: relative;
  background-color: rgba(33, 22, 13, 0.4);
}

.metodo-hero__timelineSections {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
}

.metodo-hero__timelineSection {
  flex: 20% 1 1;
  height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-transform: uppercase;
  font-size: 0.75rem;
  position: relative;
  cursor: pointer;
  transition: background-color .3s ease;
  cursor: pointer;
}

.metodo-hero__timelineSection:last-child:after, .metodo-hero__timelineSection:last-child:before {
  content: none;
}

.metodo-hero__timelineSection:after {
  content: '';
  height: 52px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  position: absolute;
  right: 0;
  bottom: 0;
}

.metodo-hero__timelineSection:hover .metodo-hero__timelineSectionBtn {
  background-color: #A67556;
}

.metodo-hero__timelineSection.is-active .metodo-hero__timelineSectionCta {
  opacity: 1;
}

.metodo-hero__timelineSection.is-active .metodo-hero__timelineSectionCta:after {
  height: 14px;
}

.metodo-hero__timelineSectionBtn {
  height: 52px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .3s ease;
}

.metodo-hero__timelineSectionCta {
  position: absolute;
  top: 0;
  opacity: 0;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
}

.metodo-hero__timelineSectionCta:after {
  content: '';
  display: block;
  width: 1px;
  height: 0;
  background-color: white;
  position: absolute;
  bottom: 0;
  transition: all .3s ease;
  left: 50%;
}

.metodo-hero__timelineProgressBar {
  background-color: #A67556;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 90%;
  left: 0;
  right: 0;
}

.metodo-slider {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 140;
  display: none;
}

.metodo-heroSlide {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  display: block;
}

.metodo-slider__navigation {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4;
}

.metodo-slider__btn {
  background-color: #A67556;
  position: relative;
  width: 50px;
  height: 50px;
  display: block;
  margin-bottom: 1px;
  cursor: pointer;
}

.metodo-slider__btn svg {
  width: 14px;
  height: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.metodo-slider__btn svg path {
  fill: white;
}

.metodo-slider__btn.swiper-button-disabled {
  opacity: 0.5;
}

.metodo-slider__btn:not(.swiper-button-disabled):hover {
  background-color: white;
}

.metodo-slider__btn:not(.swiper-button-disabled):hover svg path {
  fill: #A67556;
}

.metodo-heroSlide__viewport {
  width: 100%;
  height: 80vw;
  background-color: #21160D;
  background-size: cover;
  background-position: center;
}

.metodo-heroSlide__viewportSlide {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.metodo-heroSlide__text {
  padding: 2rem 100px;
  padding-top: 100px;
  font-size: 1.25rem;
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0, 0, 0);
  text-align: left;
  line-height: 1.5em;
  padding: 1rem 50px;
  padding-left: 2rem;
  padding-right: 60px;
  padding-top: 50px;
  background-color: #fff;
  height: auto;
  z-index: 130;
}

.metodo-heroSlide__text p {
  margin-bottom: 2rem;
}

.metodo-heroSlide__text h3 {
  font-weight: 600;
  font-size: 1em;
}

.metodo-heroSlide__title {
  text-transform: uppercase;
  margin-bottom: 2rem;
  color: #A67556;
  font-size: 0.875rem;
}

.metodo-heroSlide__subtitle {
  font-size: 1.25rem;
  line-height: 1.5em;
  font-weight: 600;
  font-family: "Playfair Display", serif;
}

.ui-button--mobile_metodo_cta {
  z-index: 20;
  display: block;
  height: 70px;
}

.ui-button--mobile_metodo_cta .ui-button__inner {
  line-height: 70px;
}

@media screen and (min-width: 1025px) {
  .metodo-heroSlide {
    display: flex;
    overflow: hidden;
  }
  .metodo-hero__cta {
    display: none;
  }
  .metodo-hero__video {
    display: block;
  }
  .ui-button--mobile_metodo_cta {
    display: none;
  }
  .metodo-hero__controlBar {
    display: flex;
  }
  .metodo-heroSlide__viewport {
    flex: 50% 0 0;
    height: 100%;
  }
  .metodo-heroSlide__text {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    padding: 2rem 100px;
    padding-top: 6rem;
  }
}

@media screen and (max-width: 450px) {
  .metodo-hero__timelineSections {
    flex-wrap: wrap;
  }
  .metodo-hero__timelineSections .metodo-hero__timelineSection {
    flex: 50% 0 0;
    height: 52px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  .metodo-hero__timelineProgressBar {
    bottom: 200%;
  }
  .metodo-hero__timelineSection.is-active {
    background-color: #A67556;
  }
  .metodo-hero__timelineSection.is-active .metodo-hero__timelineSectionBtn {
    display: none;
  }
  .metodo-hero__timelineSection.is-active .metodo-hero__timelineSectionCta:after {
    display: none;
  }
}

.metodo-gallery {
  background-color: #150a0a;
  height: 100vh;
}

.metodo-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.slide {
  height: 100vh;
  overflow: hidden;
  /*** SLIDE 2 in (poi escluso 1) **/
}

.slide .slide-inner {
  position: relative;
  height: 100%;
  margin: 0 2rem;
}

.slide svg {
  max-height: 900px;
}

.slide:not(.slide-0) .svg-embed svg {
  display: block;
  height: 100%;
  width: 200%;
  transform: translate(-10%, -21%);
}

.slide:not(.slide-0) .svg-embed svg path {
  fill: none;
}

.slide__text {
  color: #fff;
}

.svg-embed {
  max-width: 100%;
}

.owl-dot.active {
  background-color: #956c55;
}

.slide__title {
  text-transform: uppercase;
  color: #956c55;
  margin-bottom: 1rem;
}

.slide__num {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20rem;
  font-family: "Playfair Display";
  transform: translate(0, -50%);
  z-index: -1;
  color: #302320;
}

.slide__content {
  position: absolute;
  top: 55%;
  left: 0;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}

.slide-0 {
  color: #956c55;
  text-align: center;
}

.slide-0 .title__serif {
  font-size: 2rem;
  margin-top: 1rem;
  color: #956c55;
}

.slide-0 path {
  fill: #956c55;
}

.owl-dots {
  display: none;
}

.slide-4 .slide__num {
  position: absolute;
  left: initial;
  right: 5%;
}

.slide-5 .slide__num {
  display: none;
}

.slide__image {
  margin: 0;
  position: relative;
  z-index: -1;
}

.slide__image img {
  width: auto !important;
  height: auto;
  display: block;
  max-height: 340px;
  opacity: .3;
  transform: opacity .3s ease-out;
}

.active.center .slide__image img {
  opacity: 1;
}

.slide__text br {
  display: none;
}

@media screen and (min-width: 600px) {
  .metodo-gallery {
    height: 900px;
  }
  .slide {
    height: 900px;
    overflow: visible;
  }
  .slide .slide-inner {
    margin: 0 auto;
  }
  .slide:not(.slide-0) .svg-embed svg {
    width: 100%;
    transform: none;
  }
  .slide__text br {
    display: inline;
  }
  .slide-0 {
    width: 768px;
  }
  .metodo-logo {
    width: auto;
  }
  .slide__content {
    top: 70%;
    font-size: 1.2rem;
    line-height: 1.7;
    width: 100%;
  }
  .slide__image img {
    max-height: 670px;
  }
  .table {
    width: 60%;
    display: flex;
    margin: 0 auto;
  }
  .table .col:first-child {
    margin-right: 2rem;
  }
}

.owl-next,
.owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 0 none;
  width: 3rem;
  height: 3rem;
  background: url(/images/icons/arrow--left__white.svg) #A67556 no-repeat center;
  background-size: auto 70%;
  padding: 1rem;
}

.owl-next span,
.owl-prev span {
  display: none;
}

.owl-next.disabled,
.owl-prev.disabled {
  cursor: default;
}

.owl-next {
  transform: translateY(-50%) rotate(-180deg);
  right: 0;
}

.container_section_custom {
  height: 551px;
  background: #302320;
}

@media screen and (max-width: 1024px) {
  .container_section_custom {
    height: auto;
  }
}

.container_section_custom .swiper-pagination {
  height: 30px;
  bottom: 65px;
  width: 50%;
  position: relative;
  z-index: 100;
}

@media screen and (max-width: 1024px) {
  .container_section_custom .swiper-pagination {
    bottom: 48px;
  }
}

.container_section_custom .slider-navigation-wrapper {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 50px;
}

@media screen and (max-width: 1024px) {
  .container_section_custom .slider-navigation-wrapper {
    display: none;
  }
}

.container_section_custom .slider-navigation-wrapper .slider-navigation-button {
  padding-bottom: 50px;
  width: 50px;
}

.container_section_custom .slider-navigation-wrapper .swiper-button-disabled {
  visibility: hidden;
}

.container_section_custom .slider-navigation-wrapper .slider-button-next {
  right: 0px;
  position: absolute;
  margin-top: -50px;
}

.container_section_custom .container_img {
  background: #302320;
  height: 100%;
  width: 40%;
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media screen and (max-width: 1024px) {
  .container_section_custom .container_img {
    min-height: 500px;
    float: none;
    width: 100%;
  }
}

@media screen and (max-width: 520px) {
  .container_section_custom .container_img {
    min-height: 310px;
    float: none;
    width: 100%;
  }
}

.container_section_custom .container_info {
  height: 100%;
  width: 60%;
  float: right;
  padding-top: 56px;
  padding-left: 120px;
}

@media screen and (max-width: 1024px) {
  .container_section_custom .container_info {
    height: 100%;
    width: calc(100% - 36px);
    margin: auto;
    float: none;
    padding-top: 50px;
    padding-left: 0px;
  }
}

.container_section_custom .container_info .intro_article {
  text-align: left;
  font-family: "Playfair Display", serif;
  color: white;
}

.container_section_custom .container_info .intro_article h1 {
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.8px;
  margin-left: 0px;
}

@media screen and (max-width: 1024px) {
  .container_section_custom .container_info .intro_article h1 {
    font-size: 30px;
    letter-spacing: 0.6px;
  }
}

.container_section_custom .container_info .intro_article span {
  font-size: 100px;
  font-weight: bold;
  color: rgba(166, 117, 86, 0.1);
  line-height: 0.88;
  letter-spacing: 2px;
  position: relative;
  top: -46px;
  margin-bottom: 78px;
  margin-left: -40px;
}

@media screen and (max-width: 1024px) {
  .container_section_custom .container_info .intro_article span {
    font-size: 50px;
    letter-spacing: 1px;
    color: rgba(166, 117, 86, 0.05);
    top: -34px;
    margin-left: -15px;
  }
}

.container_section_custom .container_info .content_article {
  text-align: left;
  width: calc(100% - 141px);
  color: white;
  margin-top: 32px;
}

@media screen and (max-width: 1024px) {
  .container_section_custom .container_info .content_article {
    margin-top: 0px;
    width: 100%;
  }
}

.container_section_custom .container_info .content_article span {
  border-bottom: 3px solid #956c55;
  font-family: "Playfair Display", serif;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.6px;
  display: inline-block;
  width: auto;
  float: left;
  padding-bottom: 26px;
  margin-right: 10%;
  margin-bottom: 264px;
}

@media screen and (max-width: 1024px) {
  .container_section_custom .container_info .content_article span {
    font-size: 24px;
    padding-bottom: 18px;
    margin-bottom: 20px;
    float: none;
  }
}

.container_section_custom .container_info .content_article p {
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 0.4px;
  display: block;
  max-width: 642px;
  min-width: 400px;
  margin: auto;
}

@media screen and (max-width: 1024px) {
  .container_section_custom .container_info .content_article p {
    min-width: auto;
    max-width: auto;
    padding-bottom: 60px;
    font-size: 16px;
    letter-spacing: 0.32px;
    margin: 0;
  }
}

.container_section_custom_sec {
  height: 550px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

@media screen and (max-width: 768px) {
  .container_section_custom_sec {
    height: auto;
  }
}

.container_section_custom_sec .container_section_inner {
  height: auto;
  position: absolute;
  width: 100%;
  max-width: 507px;
  top: 50%;
  left: 158px;
  transform: translate(0, -50%);
  color: white;
}

@media screen and (max-width: 768px) {
  .container_section_custom_sec .container_section_inner {
    height: auto;
    position: relative;
    width: calc(100% - 36px);
    top: 0;
    left: 0;
    transform: none;
    color: white;
    margin: auto;
  }
}

.container_section_custom_sec .container_section_inner h1 {
  font-family: "Playfair Display", serif;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.8px;
  margin-bottom: 36px;
  max-width: 507px;
  display: block;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .container_section_custom_sec .container_section_inner h1 {
    font-family: "Playfair Display", serif;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0.8px;
    margin-bottom: 20px;
    max-width: 507px;
    display: block;
    text-align: center;
    padding-top: 40px;
  }
}

.container_section_custom_sec .container_section_inner p {
  font-size: 20px;
  letter-spacing: 0.28px;
  line-height: 1.5;
  display: block;
  max-width: 507px;
  text-align: center;
  margin-top: 16px;
}

@media screen and (max-width: 768px) {
  .container_section_custom_sec .container_section_inner p {
    padding-bottom: 40px;
  }
}

.custom_add_as {
  font-weight: bold;
  font-family: "Playfair Display",serif;
  margin-top: -10px;
}

.storia-viewport {
  display: none;
}

.storia-hero {
  padding: 2rem 1rem;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  color: #21160D;
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}

@media screen and (min-width: 767px) {
  .storia-hero {
    min-height: 400px;
    max-height: 400px;
    height: 80vh;
    padding: 5rem 1rem;
  }
}

.storia-hero__title {
  margin-bottom: 2rem;
}

.storia-year {
  text-align: center;
}

.storia-year__number {
  font-size: 7rem;
  color: #A67556;
  font-family: "Playfair Display", serif;
  margin-bottom: -1.157rem;
  position: relative;
  z-index: 3;
}

.storia-episode {
  margin: 0 auto;
  max-width: 400px;
  width: 90%;
  text-align: center;
  padding-bottom: 140px;
  position: relative;
}

.storia-episode:after {
  content: '';
  transition: all .3s ease-out;
  width: 1px;
  background-color: #A67556;
  position: absolute;
  bottom: 20px;
  height: 100px;
  left: 50%;
}

.storia-episode:last-child:after {
  content: none;
}

.storia-episode:before {
  content: attr(data-year);
  font-size: 7.5rem;
  color: #A67556;
  font-family: "Playfair Display", serif;
  z-index: 4;
  text-align: center;
  width: 100%;
  display: block;
  line-height: 0.84em;
  z-index: 4;
  position: relative;
}

@media screen and (min-width: 1025px) {
  .storia-episode:before {
    content: none;
  }
}

.storia-episode__picture {
  margin: 0 auto;
  margin-bottom: 1rem;
  display: block;
}

.storia-episode__picture img {
  width: 100%;
  display: block;
}

.storia-episode__title {
  color: #A67556;
  text-transform: uppercase;
  padding-top: 2rem;
  font-size: .875rem;
}

.storia-episode__text {
  margin: 0;
  padding-bottom: 3rem;
}

.storia-navigation {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  text-align: right;
  display: none;
}

.storia-navigation__year {
  display: block;
  vertical-align: middle;
  position: relative;
  width: 60px;
  height: 20px;
  cursor: pointer;
  transform: translateX(16px);
}

.storia-navigation__year:hover .storia-navigation__yearNumber {
  opacity: 1;
}

.storia-navigation__year:hover .storia-navigation__yearLine {
  background-color: #21160D;
}

.storia-navigation__year:hover .storia-navigation__yearDot {
  background-color: #21160D;
}

.storia-navigation__yearNumber {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.storia-navigation__yearLine {
  display: inline-block;
  height: 1px;
  width: 26px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -2px;
  background-color: #A67556;
}

.storia-navigation__yearDot {
  content: '';
  display: block;
  position: absolute;
  right: 26px;
  top: 50%;
  margin-top: -4px;
  width: 5px;
  height: 5px;
  border-radius: 4px;
  background-color: #A67556;
}

.storia-navigation__year--decade {
  transform: translateX(6px);
}

.storia-navigation__decadeGroup .storia-navigation__years {
  display: none;
}

.storia-navigation__decadeGroup.is-active .storia-navigation__years {
  display: block;
}

.storia-navigation__decadeGroup.is-active .storia-navigation__year--decade .storia-navigation__yearNumber {
  display: block;
  opacity: 1 !important;
}

.storia-navigation__year.is-active .storia-navigation__yearNumber {
  opacity: 1;
}

@media screen and (min-width: 1025px) {
  .storia-navigation {
    display: block;
  }
  .storia-wrapper {
    background-color: white;
    color: #21160D;
  }
  .storia-container {
    display: flex;
    position: relative;
  }
  .storia-viewport {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    display: block;
    width: 50vw;
    background-color: #21160D;
    z-index: 3;
  }
  .storia-viewport__year {
    position: absolute;
    font-size: 7.5rem;
    color: #A67556;
    font-family: "Playfair Display", serif;
    top: 50%;
    z-index: 4;
    margin-left: 100px;
    transform: translateY(-50%);
  }
  .storia-viewport__picture {
    position: absolute;
    top: 50%;
    right: -60px;
    width: 60%;
    min-width: 400px;
    z-index: 3;
    transform: translateY(-50%);
    box-shadow: 0 0 20px rgba(33, 22, 13, 0.4);
  }
  .storia-viewport__picture img {
    width: 100%;
    display: block;
    margin: 0;
  }
  .storia-wrapper {
    width: 50%;
    position: relative;
    margin-left: 50%;
  }
  .storia-hero {
    margin-top: 5rem;
    height: auto;
    min-height: auto;
    max-width: 400px;
    padding-bottom: 0;
  }
  .storia-year__number {
    display: none;
  }
  .storia-episode {
    max-width: 420px;
    margin: 0 auto;
  }
  .storia-episode__picture {
    display: none;
  }
  .storia-episode__text {
    font-size: 1.25rem;
  }
}

.areaStampa-hero {
  width: 100%;
  background-color: #21160D;
  padding-top: 7rem;
  color: white;
}

.areaStampa-covers {
  max-width: 1180px;
  margin: 0 auto;
  margin-top: 4rem;
  white-space: nowrap;
  overflow-x: scroll;
  padding: 0 calc(50vw - 130px);
  -webkit-overflow-scrolling: touch;
}

.areaStampa-covers__column {
  overflow-x: visible;
  white-space: nowrap;
  display: inline-block;
}

.areaStampa-covers__cover {
  display: inline-block;
  margin-bottom: 40px;
  box-shadow: 0 0 10px rgba(33, 22, 13, 0.7);
}

.areaStampa-covers__cover:last-child {
  margin-bottom: -40px;
}

.areaStampa-list {
  list-style: none;
  max-width: 540px;
  width: 90%;
  margin: 0 auto;
}

.areaStampa-item {
  margin-bottom: 2rem;
}

.areaStampa-item__date {
  font-size: 12px;
}

.areaStampa-item__title {
  color: #21160D;
  display: block;
  font-style: italic;
  margin: 1rem 0 0.25rem;
  line-height: 1em;
  position: relative;
  transition: color .3s ease-in-out;
}

.areaStampa-item__title:before {
  content: url("/images/icons/icon--down.svg");
  position: absolute;
  transform: translateX(-36px);
  transition: top .3s ease-in-out;
  top: 0px;
  width: 16px;
  height: 30px;
  text-align: center;
  color: #A67556;
}

.areaStampa-item__title:hover {
  color: #A67556;
}

.areaStampa-item__title:hover:before {
  top: 0.5rem;
}

.link-traduzione {
  font-size: 1rem;
  color: #21160D;
  transition: color .3s ease-in-out;
}

.link-traduzione:hover {
  color: #A67556;
}

.areaStampa-yearSelector {
  display: block;
  margin: 100px auto 50px;
  width: 100%;
  max-width: 210px;
}

@media screen and (min-width: 960px) {
  .areaStampa-covers {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0;
    overflow: visible;
  }
  .areaStampa-covers__column,
  .areaStampa-covers__cover {
    display: block;
  }
}

#news {
  margin-top: 100px;
}

#news h1 {
  line-height: 1;
  margin-bottom: 50px;
}

#news .news-yearSelector {
  display: block;
  margin: 0px auto 50px;
  width: 100%;
  max-width: 210px;
}

.news-card {
  margin: 0 auto;
  background-image: url(/images/icons/preloader.gif);
  background-position: center;
  background-repeat: no-repeat;
  background-color: #FAFAFA;
  background-size: auto 30px;
  display: block;
  padding: 1.75rem;
  cursor: pointer;
  margin-top: 20px;
  position: relative;
}

.news-card:first-child {
  margin-top: 0px;
}

.news-card--open {
  cursor: inherit;
}

.news-card__wrapper {
  background-color: #FAFAFA;
  transition: opacity .3s ease-in-out;
}

.is-loading .news-card__wrapper {
  opacity: 0;
}

@media screen and (min-width: 600px) {
  .news-card__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.is-opened .news-card__wrapper {
  display: block;
}

.news-card__content {
  position: relative;
  vertical-align: top;
  width: 100%;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 1024px) {
  .news-card__content {
    margin-bottom: 0px;
    padding-right: 1rem;
  }
}

.news-card__content a {
  color: #A67556;
}

.news-card__date {
  font-size: 0.7em;
}

.news-card__title {
  line-height: normal;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-weight: 600;
  color: #21160D;
}

.news-card__imageWrapper {
  width: 100%;
  position: relative;
}

@media screen and (min-width: 767px) {
  .news-card__imageWrapper {
    max-width: 330px;
    margin-left: 1rem;
  }
}

.is-opened .news-card__imageWrapper {
  max-width: 100%;
  margin: 0 0 1rem 0;
}

.news-card__image {
  display: block;
  width: 100%;
  height: auto;
}

.news-card__social {
  margin-bottom: 1rem;
  display: block;
  line-height: 0;
}

@media screen and (min-width: 767px) {
  .news-card__social {
    position: absolute;
    bottom: 1.75rem;
    left: 1.75rem;
    margin: 0;
  }
}

.news-card--open .news-card__imageWrapper {
  max-width: initial;
  margin: 0;
  margin-bottom: 2rem;
}

.news-card--open .news-card__social {
  position: static;
  margin-bottom: 2rem;
  margin-left: 0;
}

.news-card--open .news-card__wrapper {
  display: block;
}

.news-card--open .news-card__heading {
  margin-bottom: 2rem;
}

.news-card--open .news-card__content {
  margin-bottom: 2rem;
}

.news-container {
  width: 100%;
  display: inline-block;
  position: relative;
  vertical-align: top;
}

@media screen and (min-width: 767px) {
  .news-container {
    width: 100%;
    padding: 0 105px;
  }
}

@media screen and (min-width: 1024px) {
  .news-container {
    width: 74%;
    padding: 0;
    padding-right: 90px;
  }
}

.news-sidebar {
  position: relative;
  vertical-align: top;
  background-color: white;
  display: inline-block;
  width: 100%;
  padding: 0 15px;
  margin-top: 50px;
}

@media screen and (min-width: 767px) {
  .news-sidebar {
    width: 100%;
    padding: 0 105px;
  }
}

@media screen and (min-width: 1024px) {
  .news-sidebar {
    width: 25%;
    padding: 0 105px 0 0;
    margin-left: -4px;
  }
}

.news-sidebar .form__newsletter {
  max-width: 100%;
  display: none;
}

@media screen and (min-width: 1024px) {
  .news-sidebar .form__newsletter {
    display: block;
  }
}

.news-sidebar .form__newsletter h5 {
  margin-bottom: 1rem;
  color: #21160D;
}

.widget_news-sidebar__video {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
  margin-top: 1rem;
}

.widget_news-sidebar__video iframe, .widget_news-sidebar__video object, .widget_news-sidebar__video embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.news {
  max-width: 1280px;
  margin: 0 auto;
}

.news-singlePage {
  padding-top: 100px;
}

.news-breadcrumb {
  font-size: 14px;
  margin-bottom: 1rem;
  text-transform: uppercase;
  color: #A67556;
}

/**
 * @todo: generalizzare questo componente visto
 * che adesso lo utilizziamo per prodotti & cocktail
 */
#cocktailsList .o-grid__item {
  display: none;
}

#cocktailsList .o-grid__item.is-visible {
  display: block;
}

.cocktailCard {
  text-align: center;
  cursor: pointer;
  display: flex;
  margin: 0 1rem;
  padding: 0 3rem;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 767px) {
  .cocktailCard {
    padding: 0 3rem;
  }
}

.cocktailCard__image {
  position: relative;
  z-index: 2;
  padding-bottom: 40px;
  transition: all .2s linear;
}

.cocktailCard__image img {
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: 400px;
}

.cocktailCard__image:after {
  position: absolute;
  content: " ";
  background: #A67556;
  width: 1px;
  height: 70px;
  left: 50%;
  bottom: 0rem;
  transition: all .2s linear;
}

.cocktailCard__heading {
  position: relative;
  padding-top: 1rem;
}

.cocktailCard__supertitle {
  margin-bottom: 0;
}

.cocktailCard:hover .cocktailCard__image {
  transform: translateY(-15px);
}

.cocktailCard:hover .cocktailCard__image:after {
  height: 85px;
  bottom: -15px;
}

/*

	Stili per il singolo cocktail

*/
.cocktail {
  display: block;
  align-items: center;
  padding-bottom: 0;
  padding-top: 72px;
}

.cocktail:after {
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
}

.cocktail-hero {
  padding-bottom: 2rem;
}

.cocktail-content {
  margin-bottom: 2rem;
  flex: 40% 0 1;
}

.cocktail-content__author {
  margin-bottom: 1rem;
  display: block;
}

.cocktail-visual {
  display: flex;
  flex: 60% 1 1;
  max-width: 840px;
  align-items: center;
}

.cocktail-visual__cocktailPicture {
  margin: 0 auto;
  width: 50%;
}

.cocktail-visual__cocktailPicture img {
  width: 100%;
}

@media screen and (min-width: 600px) {
  .cocktail-visual__cocktailPicture {
    transform: translateX(-16.7%);
  }
}

.cocktail-visual__guarnizioni {
  padding-right: 2rem;
  display: none;
  margin-left: 0;
  width: 400px;
}

.cocktail-visual__guarnizione {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  position: relative;
}

.cocktail-visual__guarnizione:before, .cocktail-visual__guarnizione:after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #21160D;
}

.cocktail-visual__guarnizione:after {
  width: 73px;
  left: -73px;
  height: 1px;
}

.cocktail-visual__guarnizione:before {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  left: -85px;
}

.cocktail-visual__guarnizioneImg {
  width: 160px;
}

.cocktail-visual__guarnizioneImg img {
  width: 100%;
}

.cocktail-visual__guarnizioneTitle {
  font-family: "Playfair Display", serif;
  font-weight: 600;
  margin-bottom: .5rem;
}

.cocktail-data {
  color: white;
  background-color: #21160D;
  padding: 1rem 0;
}

.cocktail-data__wrapper {
  max-width: 1680px;
  margin: 0 auto;
  width: 90%;
}

.cocktail-data__content {
  margin-top: 2rem;
}

.cocktail-data__title {
  font-size: 1.5rem;
  font-family: "Playfair Display", serif;
}

@media screen and (min-width: 600px) {
  .cocktail-visual__cocktailPicture {
    margin: 0;
  }
  .cocktail-visual__guarnizioni {
    display: block;
  }
}

@media screen and (min-width: 960px) {
  .cocktail-wrapper {
    display: flex;
    margin: 0 auto;
  }
  .cocktail-hero {
    display: flex;
    width: 100%;
    align-items: center;
    max-width: 1680px;
    margin: 0 auto;
    flex-direction: row-reverse;
    justify-content: center;
  }
  .cocktail-content {
    max-width: 580px;
    margin-bottom: 0;
  }
  .cocktail-data__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: top;
  }
}

.search {
  position: fixed;
  z-index: 201;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-top: 8rem;
  display: none;
}

.search-wrapper {
  max-width: 920px;
  width: 90%;
  margin: 0 auto;
}

.search-close {
  cursor: pointer;
  position: fixed;
  width: 16px;
  height: 16px;
  top: 1.45rem;
  right: 2.6rem;
}

.search-input {
  height: calc(100vh - 16rem);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  transition: height .3s ease;
}

.search-input.is-compact {
  height: 12rem;
}

.search-input__field {
  font-size: 1.875rem;
  color: #21160D;
  font-family: "Playfair Display", serif;
  height: 2.5em;
  width: 100%;
  margin-bottom: 1rem;
  border: none;
  border-bottom: 2px solid #21160D;
  transition: border-color .3s ease;
}

.search-input__field:focus {
  border-bottom-color: #A67556;
  outline: none;
}

@media screen and (min-width: 800px) {
  .search-input__field {
    font-size: 3.125rem;
  }
}

.search-results__loading {
  height: calc(100vh - 20rem);
  align-items: center;
  justify-content: center;
}

.search-results__list {
  padding-top: 2rem;
}

.search-results__numberOfResults {
  display: block;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.search-result {
  padding: 2.625rem 0;
  border-bottom: 1px solid #f6f1ee;
}

.search-result__title {
  font-size: 1.25rem;
  margin-top: 1rem;
  margin-bottom: .8rem;
  line-height: 1.6em;
  font-weight: 500;
  text-transform: uppercase;
}

footer {
  padding-bottom: 35px;
}

footer .footer__logo {
  display: block;
  margin: 60px auto 50px;
  max-width: 53px;
}

footer p {
  font-size: 10px;
  line-height: 1.6667em;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

footer .o-socials {
  margin: 45px auto 25px;
}

.text-privacy {
  margin-top: 0.5rem;
}

.footer__subtext {
  color: #B2B2B2;
  margin-top: 30px;
}

.pointed__line {
  display: block;
  padding: 0px;
  position: relative;
}

@media screen and (min-width: 767px) {
  .pointed__line {
    display: inline-block;
    padding: 0 20px;
  }
  .pointed__line:after {
    content: "•";
    line-height: 1;
    position: absolute;
    right: 6px;
    top: 45%;
    transform: translateY(-50%);
    color: #A67556;
  }
}

.form__newsletter {
  max-width: 310px;
  margin: 0 auto 60px;
}

.form__newsletter input {
  font-size: 0.9em;
  text-align: center;
}

.footer__altagamma {
  display: block;
  margin: 40px auto 30px;
  width: 100%;
  max-width: 130px;
}

.footer__altagamma img {
  display: block;
  width: 100%;
  height: auto;
}

.o-footerMenu {
  display: block;
  width: 100%;
  max-width: 630px;
  margin: 0 auto;
}

@media screen and (min-width: 767px) {
  .o-footerMenu {
    display: flex;
  }
}

.o-footerMenu .o-menu__listItem {
  width: 100%;
  display: block;
}

@media screen and (min-width: 767px) {
  .o-footerMenu .o-menu__listItem {
    vertical-align: top;
    width: auto;
    flex: 33.3333333333%;
  }
}

.o-footerMenu .o-menu__item {
  display: block;
  margin: 0px;
  padding: 6px 0;
}

@media screen and (min-width: 767px) {
  .o-footerMenu .o-menu__item {
    display: inline-block;
    margin: 0 15px;
  }
}

.o-footerMenu .o-menu__item .o-menu__singleItem {
  font-size: 12px;
  font-weight: 500;
}

#contatti a {
  color: #A67556;
}

.contatti__title {
  color: #A67556;
}

.title--center {
  text-align: center;
}

.contatti--cartina {
  width: 100%;
  max-width: 950px;
  height: auto;
}

.country ul {
  list-style: none;
  padding-left: 0;
}

.country--list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.country--list li {
  flex: 100% 0 0;
}

@media screen and (min-width: 767px) {
  .country--list li {
    flex: 33.3333333333% 0 0;
  }
}

.page-contatti_block {
  margin: 2rem 0 4rem;
}

.page-contatti_addresses {
  margin-bottom: 1rem;
}

.page-contatti_globe {
  margin-top: 100px;
}

.nonino-sostiene > .o-blocksGrid--full:first-child {
  padding-top: 70px;
  background-color: #21160D;
}

@media screen and (min-width: 767px) {
  .nonino-sostiene > .o-blocksGrid--full:first-child {
    padding-top: 0px;
  }
}

.container--404 .o-block--whiteText .t-superTitle {
  color: white;
}

.container--404 .o-block--whiteText .o-hr {
  border-color: white;
}

/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none;
}

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform .1s ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.debug {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  border: 2px solid #333;
  background-color: white;
  z-index: 999;
  opacity: .4;
  overflow-y: scroll;
  max-height: 600px;
  transform: translateY(90%);
  transition: all .3s ease;
}

.debug:hover {
  transform: translateY(0);
  opacity: 1;
}

.owl-carousel .owl-stage-outer {
  overflow: hidden;
}

/********
**
**		OVERLAY X DIRETTA PREMIO
**
********/
.home-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
}

.home-overlay__background {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(11, 11, 11, 0.8);
}

.home-overlay__content {
  /*
		   1,1  per aspect ratio 1:1
		   16,9 per aspect ratio 16:9 
		   4,3  per aspect ratio 4:3 etc
		*/
  position: relative;
  position: absolute;
  z-index: 2;
  width: 94%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-overlay__content:before {
  display: block;
  width: 100%;
  padding-top: 56.25%;
  content: "";
}

.home-overlay__content > *:first-child {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media screen and (min-width: 1024px) {
  .home-overlay__content {
    width: 70%;
  }
}

.home-overlay__closer {
  position: absolute;
  display: inline;
  z-index: 10;
  top: -50px;
  left: auto;
  right: 0;
  bottom: auto;
  color: #fff;
  opacity: .65;
  font-size: 40px;
  text-decoration: none;
  transition: opacity .25s linear 0s;
}

.home-overlay__closer:hover {
  opacity: 1;
}

.home-overlay__iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.es_spinner_image {
  display: none;
}

.es_subscription_form_submit {
  padding: 0 2rem;
  line-height: 50px;
  border-style: solid none;
  margin: 20px  auto;
  text-align: center;
  display: block;
  color: #fff;
  background: #A67556;
  transition: border-color .3s ease-in-out;
  text-transform: uppercase;
  cursor: pointer;
  transition: all  .3s .4s ease-in-out;
}

.es_subscription_form_submit:hover, .es_subscription_form_submit.is-selected {
  background-color: white;
  border-style: solid none;
  border-color: #A67556;
  color: #A67556;
}

.es_subscription_form_submit:disabled {
  opacity: 0.5;
}
