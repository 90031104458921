/**
 * @todo: generalizzare questo componente visto
 * che adesso lo utilizziamo per prodotti & cocktail
 */

#cocktailsList .o-grid__item{
	display: none;

	&.is-visible{
		display: block;
	}
}

 .cocktailCard {

 	text-align: center;
 	cursor: pointer;
 	display: flex;

 	margin: 0 1rem;
 	padding: 0 3rem;

 	align-items: center;
 	justify-content: center;

 	@include respondTo("tb") {
		
		padding: 0 3rem;

 	}

 }

 	.cocktailCard__image {
 			
 		position: relative;
 		z-index: 2;
 		padding-bottom: 40px;

 		transition: all .2s linear;

 		img {
 			
 			position: relative;
 			z-index: 3;

 			width: 100%;
 			max-width: 400px;

 		}

 		&:after {
 			
 			position: absolute;
 			content: " ";
 			background: $col-lightBrown;
 			width: 1px;
 			height: 70px;
 			left: 50%;
 			bottom: 0rem;

 			transition: all .2s linear;

 		}

 	}

	.cocktailCard__heading {
			
		position: relative;
		padding-top: 1rem;

	}

	.cocktailCard__supertitle{
		margin-bottom: 0;
	}

$hoverMovement: 15;

.cocktailCard:hover{
	.cocktailCard__image{
		transform: translateY( $hoverMovement * -1px);

		&:after{
			height:  70px + $hoverMovement;
			bottom: -1px * $hoverMovement;
		}
	}
}