$filtersBlock: '.o-filtersBlock';

#{$filtersBlock} {

	display: block;
	margin: 0px auto;
	max-width: 1680px;
	width: 100%;

}

	#{$filtersBlock}__filters {

		position: relative;
		z-index: 1;
		width: 100%;
		display: block;
		margin: 0 auto;
		list-style: none;
		padding: 0px;
		text-align: center;

	}

		#{$filtersBlock}__filter {
			
			color: $col-lightBrown;
			cursor: pointer;
			display: inline-block;
			padding: 0 15px;
			position: relative;
			//transition: color .3s 0s ease-in-out;
			text-transform: uppercase;
			font-size: _fs(14);
			font-weight: 500;

			&:before {
				
				opacity: 0;
				position: absolute;
				top: -1rem;
				left: 50%;
				display: block;
				height: 20px;
				width: 39px;
				background-image: url(#{$svgDir}icon--menu.svg);
				background-position: center;
				background-repeat: no-repeat;
				transform: translateX(-50%);

				@media screen and (min-width: 970px ){
					content: '';
				}

			}

			&:after {

				content: "•";
				line-height: 1;
				position: absolute;
				right: -7.5px;
				top: 40%;
				transform: translate3d(-50%, -50%, 0);
				color: $col-darkBrown;

			}

			&.is-active, &:hover, &:active {
		
				color: $col-darkBrown;
				
				&:before,  {
				
					opacity: 1;

				}

			}	

			&:last-child:after {
			
				content: "";

			}
		
		}

	#{$filtersBlock}__container {
		
		position: static;
		padding: 60px 0 0;
		text-align: center;

	}