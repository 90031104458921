.search{
	position: fixed;

	z-index: 201;

	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	background-color: white;

	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;

	padding-top: 8rem;

	display: none;
}

	.search-wrapper{
		max-width: 920px;
		width: 90%;
		margin: 0 auto;
	}

	.search-close {
		
		cursor: pointer;
		position: fixed;
		width: 16px;
		height: 16px;
		top: 1.45rem;
		right: 2.6rem;

	}

	.search-input{
		height: calc(100vh - 16rem);
		display: flex;

		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		text-align: left;

		transition: height .3s ease;

		&.is-compact{
			height: 12rem;
		}
	}

		.search-input__field{
			font-size: _fs(30);
			color: $col-dark;
			font-family: $t-serif;

			height: 2.5em;
			width: 100%;

			margin-bottom: 1rem;

			border: none;
			border-bottom: 2px solid $col-dark;
			transition: border-color .3s ease;

			&:focus{
				border-bottom-color: $col-lightBrown;
				outline: none;
			}
			
			@media screen and ( min-width: 800px ){
				font-size: _fs(50);
			}

		}

	.search-results__loading{
		// display: none;
		height: calc(100vh - 20rem);

		align-items: center;
		justify-content: center;
	}

	.search-results__list{
		// display: none;
		padding-top: 2rem;
	}

	.search-results__numberOfResults{
		display: block;
		margin-bottom: 2rem;
		text-transform: uppercase;
	}

	.search-result{
		padding: 2.625rem 0;
		border-bottom: 1px solid #f6f1ee;
	}

	.search-result__title{
		font-size: _fs(20);
		margin-top: 1rem;
		margin-bottom: .8rem;
		line-height: 1.6em;

		font-weight: 500;
		text-transform: uppercase;
	}