.news-container{

	width: 100%;
	display: inline-block;
	position: relative;
	vertical-align: top;

	@include respondTo("tb") {
		
		width: 100%;
		padding: 0 105px;

	}

	@include respondTo("lg") {
		
		width: 74%;

		padding: 0;
		padding-right: 90px;

	}

}