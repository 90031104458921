@mixin headerHeight(){
	
	height: 70px;

}


.o-siteHeader {

	display: block;
	width: 100%;
	position:fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	z-index: 200;

	transition: transform .4s ease,
				background-color .3s ease;

	&.is-unpinned{
		transform: translateY(-100%);
	}

	&.is-pinned{
		transform: translateY(0);
	}

	&.is-not-top{
		background-color: rgba( $col-dark, .5 );
	}

}

	.o-siteHeader__wrapper{
		display: flex;
	}

	.o-siteHeader__menuToggle,
	.o-siteHeader__logo,
	.o-siteHeader__iconsWrapper{

		font-size: _fs(15);
		display: inline-block;

		width: 100%;
		color: white;

		@include buttonReset();
		@include headerHeight();

	}

	.o-siteHeader__logo {

		background-image: url(#{$imgDir}logo.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 55px auto;

		background-color: $col-lightBrown;

		svg {

			position: relative;
			top: 50%;
			transform: translateY(-50%);
			width: 53px;

			path {
				
				fill: white;

			}

		}

	}

	.o-siteHeader__iconsWrapper {
		
		font-weight: 500;
		align-items: center;
		justify-content: flex-end;
		display: flex;
		position: relative;
		z-index: 10;

		padding-right: 1.5rem;

	}

		.o-siteHeader__icon {

			cursor: pointer;
			margin: 0 0 0 30px;
			height: 28px;
			width: 28px;

			svg{

				height: 100%;
				width: 100%;

			}

		}

		.o-siteHeader__textLink,
		.o-siteHeader__icon,
		.o-siteHeader__iconsWrapper .o-siteHeader__languageSwitcher {

			display: none;

			@include respondTo("tb"){

				display: inline-block;

			}

		}


		.o-siteHeader__icon--basket {

			margin-right: 10px;

			svg {

				height: 85%;

			}

		}

		.o-siteHeader__icon--labeled {

			position: relative;

		}

			.o-siteHeader__iconLabel {

				position: absolute;
				top: -10px;
				right: -10px;
				width: 20px;
				height: 20px;
				line-height: 20px;
				background: $col-lightBrown;
				text-align: center;
				color: white;
				font-size: 0.75rem;
				border-radius: 50%;
				font-style: normal;

			}



	.o-siteHeader__menuToggle,
	.o-siteHeader__logoWrapper
	{

		position: relative;
		z-index: 10;
		text-align: center;

		flex: 100px 0 0;

		cursor: pointer;
		background-color: $col-lightBrown;

		@include respondTo("tb"){

			flex: 105px 0 0;

		}


		&:hover, &:active, &.is-active {

			color: $col-lightBrown;
			background: white;

		}
	}

	.o-siteHeader__logoWrapper {
		line-height: 0px;
	}

	.o-siteHeader__menuToggle {

		display: flex;
		justify-content: center;
		align-items: center;

		text-transform: uppercase;

		font-weight: 500;
    	letter-spacing: 1px;

    	font-size: _fs(15);

	}

		.o-siteHeader__menuToggleText--close {
			
			display: none;
		
		}

// languageSwitcher
.o-siteHeader__languageSwitcher {

	display: flex;
	height: 100%;
	width: 70px;
	margin-right: 1rem;
	position: relative;

}

.o-siteHeader__languageSwitcher__wrapper{

	height: 100%;
	transform: translate3d(0, 0, 0);
	transition: transform .3s ease;

}

	.o-siteHeader__languageSwitcher__arrow {
		
		position: absolute;
		top: 50%;
		right: 7px;
		z-index: 10;
		transform: translateY(-50%);

		.o-siteHeader__languageSwitcher.is-open & {
		
			transform: rotate(180deg) translateY(-50%);
    		transform-origin: 50% 0%;

		}

	}

	.o-siteHeader__languageSwitcher__list {
		
		background-color: $col-lightBrown;
		width: 90px;
		color: white;
		opacity: 0;
		visibility: hidden;
		padding: 0px;
		margin: 0 0 0 -10px;
		list-style: none;

		.o-siteHeader__languageSwitcher.is-open & {
			
			opacity: 1;
			visibility: visible;

		}

	}

		.o-siteHeader__languageSwitcher__lang, .o-siteHeader__languageSwitcher__currentlang {

			text-transform: uppercase;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 100% 0 0;
			text-align: center;
			cursor: pointer;

		}

		.o-siteHeader__languageSwitcher__lang {
			
			background-color: $col-lightBrown;
			color: white;
			height: 35px;

			&:hover {

				background-color: white;
				color: $col-lightBrown;

			}

		}


.o-siteHeader.is-open{

	bottom: 0px;
	overflow: hidden;

	.o-siteHeader__iconsWrapper {

		background-color: $col-lightBrown;

		@include respondTo("tb") {
			
			background-color: transparent;

		}

	}

	.o-siteHeader__menuToggleText--open {

		display: none;
	
	}

	.o-siteHeader__menuToggleText--close {
		
		display: block;

	}

}

@mixin headerColor( $col-base, $col-hover) {

	.o-siteHeader__iconsWrapper {

		.o-siteHeader__languageSwitcher__currentlang {

			color: $col-base;

			svg path {

				fill: $col-base;

			}
			
			&:hover {

				color: $col-hover;

				svg path {

					fill: $col-hover;

				}

			}

		}

		.o-siteHeader__textLink {

			color: $col-base;

			&:hover {
				
				color: $col-hover;

			}

		}
			
		.o-siteHeader__icon {

			svg path {

				fill: $col-base;

			}

			&:hover {
				
				svg path {

					fill: $col-hover;

				}

			}

		}

	}

}

.o-siteHeader {

	@include headerColor( white, white );	

}

.o-siteHeader.bordelloDiUnCastello {
	
	@include headerColor( $col-lightBrown, white );

}

.o-siteHeader.is-not-top {
	
	@include headerColor( white, white );

}

.o-siteHeader.is-open {

	@include headerColor( $col-lightBrown, $col-lightBrown );

}