.nonino-sostiene > .o-blocksGrid--full:first-child {
	
	padding-top: 70px;
	background-color: #21160D;

	@include respondTo("tb") {

		padding-top: 0px;

	}

}