.product-features{
	position: relative;
	overflow: hidden;
}

.product-features__background{
	@include productImage;
	background-size: auto 100%;

}

	.product-features__columnFeatures{
		margin: 0 1rem 4.5rem;

		background-color: white;

		@include respondTo("tb") {
			
			margin-bottom: 0px;

		}
	}

@media screen and ( min-width: 1025px ){

	.product-features {

		position: relative;
		display: flex;
		margin: 0 auto;
		max-width: 1680px;
		width: 100%;

	}
		.product-features__wrapper {
			
			display: flex;
			position: absolute;
			left: 0px;
			top: 0px;
		    height: 100%;
		    margin: 0px;
		    -webkit-box-pack: justify;
		    justify-content: space-between;
		    width: 100%;
			
			& > *{
				padding: 0;
			}
		
		}

		.product-features__columnScheda, .product-features__columnFeatures {

			display: flex;

		}

		.product-features__columnScheda {

			width: 40%;

		}

		.product-features__productImage {
			margin: 0 auto;

		}

		.product-features__columnFeatures {
			
			background-color: rgba(255,255,255,.5);
			position: relative;
			left: inherit;
			
		    justify-content: center;
		    align-items: center;
			flex: 375px 0 0;

			&:before{

				content: '';
				display: none;

			}

		}

}

.is-unpinned{
	background-attachment: inherit;
}