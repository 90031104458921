@import "variables/variables";
@import "mixins/mixins";
@import "utility/utilities";

@import "node_modules/swiper/dist/css/swiper";
@import "node_modules/normalize-scss/sass/normalize";

@include normalize();

* {

	outline: 0;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;

}

::selection {
  background: $col-lightBrown; /* WebKit/Blink Browsers */
  color: white;
}
::-moz-selection {
  background: $col-lightBrown; /* Gecko Browsers */
  color: white;
}

body {
	margin: 0px;
	font-size: $t-baseSize;
	color: $col-darkBrown;

	color: $col-dark;
	line-height: 1.75em;

	font-family: $t-sans;

	letter-spacing: 0.02em;
	-webkit-font-smoothing: antialiased;

}

html.prevent-scroll,
html.prevent-scroll body{

	height: 100%;
	overflow: hidden;

}

h1, h2, h3, h4, h5 {

	font-weight: normal;
    line-height: 1em;
    margin: 0 0 1rem;

}


a {

	color: inherit;
	text-decoration:none;

}

p {

	margin: 0px;

}

@import "utility/utilities";
@import "elements/elements";
@import "modules/modules";

@import "modules/404";

@import "vendor/vendor";

.debug {
	width: 100%;
	height: auto;
	overflow: hidden;
	position: fixed;
	bottom: 0;
	left: 0;
	border: 2px solid #333;
	background-color: white;
	z-index: 999;

	opacity: .4;

	overflow-y: scroll;
	max-height: 600px;

	transform: translateY(90%);
	transition: all .3s ease;
}

	.debug:hover {

		transform: translateY(0);

		opacity: 1;
	}
.owl-carousel .owl-stage-outer{
	overflow: hidden;
}



/********
**
**		OVERLAY X DIRETTA PREMIO
**
********/

.home-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 999999;
  }
  
  .home-overlay__background {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(11, 11, 11, 0.8);
  }
  
  .home-overlay__content {
		/*
		   1,1  per aspect ratio 1:1
		   16,9 per aspect ratio 16:9 
		   4,3  per aspect ratio 4:3 etc
		*/
	position: relative;
	position: absolute;
	z-index: 2;
	width: 94%;
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }
  .home-overlay__content:before {
	display: block;
	width: 100%;
	padding-top: 56.25%;
	content: "";
  }
  .home-overlay__content > *:first-child {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
  }
  @media screen and (min-width: 1024px) {
	.home-overlay__content {
	  width: 70%;
	}
  }
  
  .home-overlay__closer {
	position: absolute;
	display: inline;
	z-index: 10;
	top: -50px;
	left: auto;
	right: 0;
	bottom: auto;
	color: #fff;
	opacity: .65;
	font-size: 40px;
	text-decoration: none;
	transition: opacity .25s linear 0s;
  }
  .home-overlay__closer:hover {
	opacity: 1;
  }
  
  .home-overlay__iframe {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
  }
.es_spinner_image{
	display: none;
}
.es_subscription_form_submit {
	padding: 0 2rem;
	line-height: 50px;
	border-style: solid none;
	margin:20px  auto;
	text-align: center;
	display: block;
	color:#fff;
	background: $col-lightBrown;
	transition: border-color .3s ease-in-out;
	text-transform: uppercase;
	cursor: pointer;
	transition: all  .3s .4s ease-in-out;

	&:hover, &.is-selected {

		background-color: white;
		border-style: solid none;
		border-color: $col-lightBrown;
		color:$col-lightBrown;

	}
	&:disabled{
			opacity:0.5;
	}
}
