.home-hero__note{

	display: none;

	position: absolute;

	height: auto;
	bottom: 1rem;
	left: 2rem;

	z-index: 1;

	color: white;

	text-transform: uppercase;

	&--right{
		left: auto;
		right: 2rem;

	    max-width: 250px;
	    text-align: right;
	    line-height: 1.2;

	    @include respondTo("md") {
		
			max-width: 100%;
		    line-height: inherit;

		}

		img {
			
			display: inline-block;
			height: 10px;
			margin-right: 10px;

		}
	}

	@include respondTo("tb") {
		
		display: block;

	}
}

.home-hero__marchioDistillazione{
	position: absolute;
	bottom: 0;
	left: 50%;

	z-index: 1;
	height: auto;

	img{
		display: block;
		width: 130px;
	}

	transform: translateX(-50%);
}


.touchevents {
	
	video {

		display: none;

	}

}