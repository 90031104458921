.ui-filters {

	position: relative;
	z-index: 2;
	width: 100%;
	display: block;
	margin: 0 auto;
	list-style: none;
	padding: 0px;
	text-align: center;

	&__single {
		
		cursor: pointer;
		display: inline-block;
		padding: 0 15px;
		position: relative;
		transition: color .3s 0s ease-in-out;
		text-transform: uppercase;
		font-size: _fs(14);

		&:after {

			content: "•";
			line-height: 1;
			position: absolute;
			right: -7.5px;
			top: 40%;
			transform: translate3d(-50%, -50%, 0);
			color: $col-darkBrown;

		}

		&.is-selected, &:hover, &:active {
	
			color: $col-lightBrown;

		}

		&:last-child:after {
		
			content: "";

		}
	
	}

}