.ui-input {

	position: relative;
	border: 1px solid $col-lightBrown;
	border-left: none;
	border-right: none;

	text-align: left;

	i{
		
		&:before,
		&:after{
			content: '';
			border: 1px solid $buttonColor;
			border-top: none;
			border-bottom: none;
		
			position: absolute;
			left: 0;
			right: 0;
			
			transition: all .3s ease-in-out;
		}
		
		&:before{
			top: 0;
			bottom: 100%;
		}
		
		&:after{
			top: 100%;
			bottom: 0;
		}
		
	}

	&.is-focus {
	
		background-color: white;
		transition: all .3s 0 ease-in-out;
		color: $buttonColor;
		
		&:before,
		&:after{
			width: 100%;
			transition: width .3s ease-in-out;
		}
		
		i{
			
			&:before,
			&:after{
				transition: all .3s ease-in-out;
			}

			&:before{
				bottom: 50%;
			}

			&:after{
				top: 50%;
			}
			
		}
		
	}

	

}

	.ui-input__label{

		font-size: 14px;
		position: absolute;

		text-transform: uppercase;

		top: 50%;
		left: 1rem;
		transform: translateY(-50%);
		letter-spacing: -0.02em;

		transition: all .3s ease;

		color: $col-lightBrown;

		.is-focus &{
			top: -3px;
			left: 1rem;

			transform: translateY(0);

			font-size: 10px;

		}

	}

	.ui-input__field {

		background: transparent;
		position: relative;
		border: none;
		height: $heightMid;
		padding: 0 1rem;
		text-transform: uppercase;
		color: $col-darkBrown;
		width:100%;
		text-align: center;

		//padding-top: 10px;

		font-family: $t-sans;

		text-align: left;

		&:focus{
			outline: none;
		}

		@include placeholder {
			color: $col-lightBrown;
		}

	}