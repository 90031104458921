$buttonColor: $col-lightBrown;
$buttonHeight: $heightMid;

.ui-button {
	background: none;
	border: none;

	font-family: $t-sans;
	font-size: _fs(14);

	padding: 0 2rem;
	margin-bottom: 1rem;
	text-align: center;

	display: inline-block;

	min-width: 200px;

	cursor: pointer;
	text-transform: uppercase;

	position: relative;

}

	.ui-button__inner {
		position: relative;
		z-index: 10;
		padding: 0.813rem 0;
		line-height: 1.5rem;
	}

// full
.ui-button--full{

	width: 100%;

	display: block;
}

// CTA
.ui-button--cta{

	$initialBordersWidth: 52px;
	color: $buttonColor;

	transition: all .3s ease-in-out;
	
	&:before,
	&:after{
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		bottom: 0;
		right: 0;
		
		transform: translateX(-50%);
		width: $initialBordersWidth;
		
		z-index: 3;
		
		transition: all .3s .3s ease-in-out;
	}
	
	&:before{
		border-top: 1px solid $buttonColor;
	}
	
	&:after{
		border-bottom: 1px solid $buttonColor;
	}
	
	i{
		
		&:before,
		&:after{
			content: '';
			border: 1px solid $buttonColor;
			border-top: none;
			border-bottom: none;
		
			position: absolute;
			left: 0;
			right: 0;
			
			transition: all .3s ease-in-out;
		}
		
		&:before{
			top: 0;
			bottom: 100%;
		}
		
		&:after{
			top: 100%;
			bottom: 0;
		}
		
	}
	
	&:hover{
	
		background-color: $col-lightBrown;
		transition: all .3s .4s ease-in-out;
		color: white;
		
		&:before,
		&:after{
			width: 100%;
			transition: width .3s ease-in-out;
		}
		
		i{
			
			&:before,
			&:after{
				transition: all .3s .3s ease-in-out;
			}

			&:before{
				bottom: 50%;
			}

			&:after{
				top: 50%;
			}
			
		}
		
	}

	// &:focus{

	// 	background-color: white;
	// 	transition: all .3s .4s ease-in-out;
	// 	color: $col-darkBrown;
		
	// 	&:before,
	// 	&:after{
	// 		width: 100%;
	// 		transition: width .3s ease-in-out;
	// 		border-color: $col-darkBrown;
	// 	}

	// 	i{
			
	// 		&:before,
	// 		&:after{
	// 			border-color: $col-darkBrown;
	// 		}

	// 		&:before{
	// 			bottom: 50%;
	// 		}

	// 		&:after{
	// 			top: 50%;
	// 		}
			
	// 	}
		
	// }
}

	.ui-button--ctaProduct{

		margin-top: 2.5rem;
		margin-bottom: 0px;

	}

	.ui-button--ctaRicetta{

		margin-top: 0px;
		margin-bottom: 2.5rem;

	}

// FINE CTA



// PROGRESS
.ui-button--progress{

	background-color: $buttonColor;	
	color: white;
	transition: all .3s ease-in-out;
	
	i{
		
		&:before,
		&:after{
			background: $col-dark;
			content: '';
			border-top: none;
			border-bottom: none;
		
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			width: 0px;
			transition: all .3s ease-in-out;
		}
		
	}
	
	&:hover{
		
		i{
			
			&:before,
			&:after{
				width: 100%;
				transition: all .3s 0s ease-in-out;
			}
			
		}
		
	}
}

.ui-button--white{

	color: white;

	&, &:before, &:after, *, *:before, *:after {
		border-color: white !important;
	}

	&:hover{
	
		color: $col-lightBrown;
		background-color: white;

	}
}

// buy
.ui-button--buy {

	background: $col-lightBrown;
	color: white;
	display: block;
	margin: 0px;
	height: $heightBig;
	transition: all .3s ease-in-out;

	.ui-button__inner {
		
		line-height: 2.75rem;

	}

	i:after {
		
		display: block;
		color: $buttonColor;
		width: 17px;
		height: 17px;
		position: absolute;
		right: 30px;
		top: 50%;
		transform: translateY(-50%);
	}


	@include respondTo("tb") {
		background: white;
		color: $col-lightBrown;
	}
}

// capacita
	.ui-button--capacita {
		
		cursor: pointer;
		width: 100%;
		padding: 0px;
		background-color: white;
		border: 1px solid $col-lightGrey;
		height: $heightBig;
		margin: 0 0 20px;
		transition: border-color .3s ease-in-out;

		&:hover, &.is-selected {

			border-color: $col-lightBrown;

		}

		&:nth-child(1n + 2) {
			
			margin-left: 20px;

		}

	}

.ui-button--solid{
	color: white;
	background-color: $col-lightBrown;
}
