.o-listElements {

	display: table;
	width: 100%;
	margin: 0px;
	padding: 0px;
	list-style: none;

}

	.o-listElements__single {

		padding: 0.5rem 0;
		line-height: 1.375em;
		

		.o-listElements--underlined & {

			border-top: 1px solid $col-lightGrey;
			padding-bottom: 0.5rem;
			padding-top: 0.5rem;

			&:first-child {

				border: none;
			
			}

		}

	}

		.o-listElements__icon, .o-listElements__text {

			display: table-cell;
			vertical-align: middle;

			.o-listElements--sensazioni & {

				//vertical-align: top;

			}

		}

		.o-listElements__icon {

			width: 60px;
			
			svg {
				
				width: 60px;
				height: 60px;
				display: block;
				margin: 0 auto;

			}

		}

		.o-listElements__title {
			text-transform: uppercase;
			font-weight: 600;
			font-size: 14px;
		}

		.o-listElements__text {

			padding: 0 1rem;
			text-align: left;

			p{
				display: inline-block;
			}

		}