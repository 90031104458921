/** 
 *
 * Stili per la pagina prodotto
 * 
 */
@import 'mixins';

@import 'hero';
	@import 'cartForm';

@import 'features';
@import 'altreInfo';
@import 'confezioni';
@import 'abbinamentiImages';

#prodotti {
	
	.o-grid__item {
		
		display: none;

		&.is-visible {

			display: inline-block;

		}

		.cocktailCard__image img {
			
			max-width: 300px;

		}


	}

}

.product-hero, .product-features {
	min-height: 100vh;
}

.product-hero{
	// position: fixed;
	z-index: 10;
}

	.product-hero__contentWrapper {
		position: relative;
		height: 100%;

		width: 100%;
		max-width: 1680px;

		display: block;
		margin: 0 auto;

	}

.product-materiaPrima{
	color: white;

	position: absolute;
	right: 0;
	left: 50%;

	bottom: 0;
	top: 50%;

	z-index: 20;
	color: white;

	text-align: center;

}

	.product-materiaPrima__wrapper{
		display: none;

		text-align: left;
		position: relative;

		width: 220px;

		@media screen and ( min-width: 1281px ){
			display: inline-block;
		}

	}
	
		.product-materiaPrima__arrow{

			height: 174px;
			width: 40px;
			
			position: absolute;
			top: 50%;
			left: -130px;

			.frut & {
		
				height: 94px;
				left: -190px;

			}

			.ue-lacquavite-duva & {
		
				height: 54px;
				left: -150px;

			}

			.gioiello & {
		
				height: 54px;
				left: -210px;

			}

			border-left: 1px solid white;

			&:before,
			&:after{
				content: '';
				position: absolute;
				background-color: white;

				top: 50%;
			}

			&:before{
				top: 0;
				width: 110px;
				height: 1px;

				.frut & {
		
					width: 170px;

				}

				.ue-lacquavite-duva & {
		
					width: 130px;

				}

				.gioiello & {
		
					width: 190px;

				}
			}

			&:after{
			
				top: 180px;
				left: -4px;
				width: 7px;
				height: 7px;
				border-radius: 6px;

				.frut & {
		
					top: 100px;

				}

				.ue-lacquavite-duva & {
		
					top: 60px;

				}

				.gioiello & {
		
					top: 60px;

				}
			}

		}

		.product-materiaPrima__icon{

			position: absolute;
			bottom: -37px;
			left: -64px;

			width: 54px;
			height: 54px;

			svg * {
				fill: white;
			}

		}

	.product-materiaPrima__title{
		margin: 0;
		font-weight: 500;
		font-family: $t-serif;

		font-size: _fs(20);
	}

	.product-materiaPrima__text{
		font-size: _fs(20);
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

.product-cocktails{
	display: block;
	margin: 0 auto;
	max-width: 1680px;
	width: 100%;

	.slider-navigation-wrapper {
		
		display: none;
		
		@include respondTo("tb") {
		
			display: inline-block;

		}

	}
}