@import 'cocktailsSlider';
@import 'hero';

.productsSlider-slide{
	width: 80%;
	max-width: 360px;
	padding: 1rem;

	display: block;

}

	.productSlider-slide__heading{
		display: flex;
		height: 200px;

		flex-direction: column;
		align-content: center;
		justify-content: center;

		opacity: 0;

		position: absolute;
		top: -2rem;
		left: 0;
		right: 0;
		padding: 0 1rem;
		transition: all .3s ease-in-out;

		.productsSlider-slide:hover &, .productsSlider-slide.is-active & {
			
			opacity: 1;
			top: 0px;

		}
	}

	.productSlider-slide__image{
		display: block;

		img{
			width: 100%;
		}
	}