@import 'newsCard';
@import 'newsContainer';
@import 'newsSidebar';

.news{
	max-width: 1280px;
	margin: 0 auto;
}

.news-singlePage{
	padding-top: 100px;
}

.news-breadcrumb{
	font-size: 14px;
	margin-bottom: 1rem;

	text-transform: uppercase;

	color: $col-lightBrown; 
}