.o-imageBlock{
	overflow: hidden;
	width: 100%;
	text-align: center;
	
	display: flex;
	justify-content: center;
	align-items: center;
	
	position: relative;
	
	flex-grow: 2;
}

	.o-imageBlock__background {
	    
	    position: absolute;
	    left: 0;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    z-index: 1;
		

		.o-imageBlock--attivo & {
			
			background-color: rgba(0, 0, 0, 0.26);

		}
	    
	}

	.o-imageBlock__image {

		width: 100%;

	}

.o-imageBlock__photoCredit{
	position: absolute;
	z-index: 3;

	right: 2rem;
	bottom: 2rem;

	line-height: 1.4em;

	text-align: right;

	font-size: _fs(12);

	color: $col-lightBrown;
	text-transform: uppercase;

}