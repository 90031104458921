.areaStampa-hero{
	width: 100%;
	background-color: $col-darkBrown;

	padding-top: 7rem;
	color: white;
}

	.areaStampa-covers{
		
		max-width: 1180px;
		margin: 0 auto;
		margin-top: 4rem;

		white-space: nowrap;
		overflow-x: scroll;

		padding: 0 calc(50vw - 130px);

		-webkit-overflow-scrolling: touch;

	}

		.areaStampa-covers__column{
			overflow-x: visible;
			white-space: nowrap;

			display: inline-block;
		}

		.areaStampa-covers__cover{
			
			display: inline-block;

			margin-bottom: 40px;
			box-shadow: 0 0 10px rgba( $col-dark, .7 );

			&:last-child{
				margin-bottom: -40px;
			}
		}

		.areaStampa-list{
			list-style: none;
			max-width: 540px;
			width: 90%;
			margin: 0 auto;
		}

		.areaStampa-item{
			margin-bottom: 2rem;
		}

		.areaStampa-item__date{
			font-size: 12px;
		}

		.areaStampa-item__title{
			color: $col-darkBrown;
			display: block;
			font-style: italic;
			margin: 1rem 0 0.25rem;
			line-height: 1em;
			position: relative;
			transition: color .3s ease-in-out;

			&:before{
				content: url("/images/icons/icon--down.svg");
				position: absolute;
				transform: translateX(-36px);
				transition: top .3s ease-in-out;
				top: 0px;

				width: 16px;
				height: 30px;

				text-align: center;

				color: $col-lightBrown;
			}

			&:hover {

				color: $col-lightBrown;
				
				&:before {
					
					top: 0.5rem;

				}

			}
		}

		.link-traduzione {
			
			font-size: 1rem;
			color: $col-darkBrown;
			transition: color .3s ease-in-out;

			&:hover {
				
				color: $col-lightBrown;

			}

		}


		.areaStampa-yearSelector {
			
			display: block;
			margin: ( $heightMid * 2 ) auto $heightMid;
			width: 100%;
			max-width: 210px;

		}

@media screen and ( min-width: 960px ){

	.areaStampa-covers{
		display: flex;
		flex-direction: row;

		justify-content: space-around;
		padding: 0;
		overflow: visible;
	}	

		.areaStampa-covers__column,
		.areaStampa-covers__cover{
			display: block;
		}
}