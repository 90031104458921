.product-abbinamentiImages {

	margin: 0px;
	padding: 0px;
	display: block;
	width: 100%;
	text-align: center;
	list-style: none;

	justify-content: center;

	@include respondTo("tb") {
		
		display: flex;

	}

}

	.product-abbinamentiImages__single {

		vertical-align: middle;
		margin: 0;
		display: inline-block;
		width: 50%;

		@include respondTo("tb") {
		
			width: 200px;

		}

		

		img{
			width: 100%;
			display: block;
		}

	}