.o-block{
	overflow: hidden;
	width: 100%;
	text-align: center;

	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;

	flex-grow: 2;

	padding: 2rem;

	@include respondTo("tb") {

		padding: 4rem 2rem;

	}
}

	.o-block__wrapper{

		margin: 0 auto;

		position: relative;
		z-index: 2;

		& > *{
			margin-bottom: 1rem;
		}

		& > *:last-child{
			margin-bottom: 0;
		}

	}

	.o-block__container {
		position: relative;
		z-index: 3;
		width: 100%;
	}

	.o-block__background{
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;

		background-color: #eee;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;

		z-index: 1;

		&:before,
		&:after{
			content: '';
			position: absolute;
			z-index: 3;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		&:after{
			box-shadow: inset 10px 10px 100px rgba( $col-dark, 0.25 );
		}

		&:before{
			background-color: rgba( $col-dark, .26 );
		}
	}

		.o-block__backgroundLayer{
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;

			z-index: 2;
			background-size: cover;
			background-position: center;
		}

		.o-block__backgroundLayer video{
		    position: absolute;
		}

		.o-block__backgroundLayer--wide video{
			width: 100%;
			height: auto;
			transform: translateY(-50%);
			top: 50%;
			left: 0;
		}

		.o-block__backgroundLayer--tall video{
			height: 100%;
			width: auto;
			transform: translateX(-50%);
			left: 50%;
			top: 0;
		}

.o-block__heading{
	margin: 0 auto;
}

.o-block__content{
	font-size: _fs(20);
	margin-bottom: _fs(40);
}

.o-block--whiteText{
	color: white !important;
}

.o-block--darkBrown{
	color: white;
	background-color: $col-darkBrown;
}

.o-block__featuredImage {
	text-align: center;
}

.o-block--darkBrownMobile {

	background-color: $col-darkBrown;
	@include respondTo("tb") {

		background-color: transparent;

	}

}

.o-block--lightBrown{

	color: white;
	background-color: $col-lightBrown;

	.o-hr {

		border-color: #FFF;

	}

}

.o-block--green{

	color: white;
	background-color: $col-green;

}

.o-block--fullSize {

	.o-block__wrapper {

		max-width: 750px;

	}

}

.o-block--paddedTop {

	padding-top: 100px;
	padding-bottom: 90px;

	@include respondTo("tb") {

		padding-top: 50px;
		padding-bottom: 45px;

	}

}

.o-block--quote{

	font-size: _fs(20);

	.o-block__wrapper{
		max-width: 930px;
	}

	.o-block__content{
		font-style: italic;
		font-family: $t-serif;
		line-height: 2rem;


		&:before,
		&:after{
			font-size: 34px;
			line-height: 1rem;
			display: inline-block;
			font-weight: bold;
		}

		&:before{
			content: '“';
		}

		&:after{
			content: '”';
		}
	}

}

.o-block__photoCredit{
	position: absolute;
	z-index: 3;

	right: 2rem;
	bottom: 2rem;

	text-align: right;

	font-size: _fs(12);

	color: $col-lightBrown;
	text-transform: uppercase;

}

// @todo spostare in file
.o-blockquoteCredit__author{
	font-size: _fs(20);
	margin-bottom: .5rem;
	display: block;

	font-family: $t-serif;
}

.o-blockquoteCredit__notes{
	font-size: _fs(12);
	line-height: _fs(20);
	max-width: 400px;
	margin: 0 auto;

	text-transform: uppercase;

	color: $col-lightBrown;
}

.o-blockquoteCredit__logo{
	height: 60px;
	width: auto;
}


.touchevents {

	.o-block__backgroundLayer--video {

		display: none;

	}

}

.o-block--unpadded{

	padding: 1rem 0;

}


//
@media screen and ( min-width: 960px ){

	.o-block__wrapper{
		max-width: 510px;

		.o-block--big &, .o-block--homeHero & {

			max-width: 750px;

		}

		.o-block--full & {

			max-width: 960px;

		}

		.o-block--videoCocktail & {

			max-width: 860px;

		}

		.o-slider--cantineSlider & {
			max-width:850px;
		}

	}

	.o-block--unpadded{

		padding: 1rem 0;

		.o-block__content{
			padding: 0;
		}

		.o-block__wrapper{
			max-width: 100%;
		}

	}

	.o-block--wide{

		.o-block__wrapper{
			max-width: initial;
		}

	}

	.o-block--leftText{

		.o-block__content{
			text-align: left;
		}

	}

	.o-block--contentLeft{

		justify-content: flex-start;

		.o-block__container{
			width: 50%;
			min-width: 400px;
		}

	}

	.o-block--contentRight{

		justify-content: flex-end;

		.o-block__container{
			width: 50%;
			min-width: 400px;
		}

	}

}
@media screen and ( min-width: 1025px ){

	.o-block--left {
		padding-left: 0;
		padding-right: 0;
	}

	.o-block--left .o-block__wrapper {
		left: 0px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		width: 40%;
		position: absolute;
		z-index: 2;
		padding: 0 100px;
	}
}


@media screen and ( min-width: 1200px ){

	.o-block{
		padding-left: 100px;
		padding-right: 100px;
	}


	.o-block--unpadded{

		padding-left: 0px;
		padding-right: 0px;

	}

	.o-block--left {
		padding-left: 0;
		padding-right: 0;
	}
}

@media screen and ( min-width: 1600px ){
	.o-block--left {
		padding-left: 100px;
		padding-right: 100px;
	}
}