$siteHeader: 50px;

@include respondTo("tb") {

	$siteHeader: 70px;

}

.o-blocksGrid{

	display: flex;
	margin: 0 auto;
	max-width: 1680px;
	width: 100%;
	flex-direction: column;

	position: relative;

	&--homeHero{
		height: 100vh;
		display: flex;

		background-color: $col-darkBrown;

		.o-blocksGrid__column{
			height: 100%;
		}

		@include respondTo("tb") {
		
			min-height: 600px;
			height: 90vh;

		}

	}

	&--rowTemplate__img--dx {
		
		flex-direction: column-reverse;

	}

	@include respondTo("tb") {
		
		flex-direction: initial;

	}

}

	.o-blocksGrid__column{
		
		overflow: hidden;
		display: block;
		flex-direction: column;
		padding: 0;
		height: auto;
		width: 100%;

	}

@media screen and ( min-width: 960px ){

	.o-blocksGrid{
		min-height: 560px;
		display: flex;
	
		&--big{
			height: 740px;
		}

		&--hero{
			height: 100vh;
		}

		&--homeHero{
			height: 100vh;
			width: 100%;
			max-width: inherit;

			padding-top: 0;
		}

		&--small{
			min-height: 375px;
		}

		&--slider{
			min-height: 330px;
		}

		&--quote{
			min-height: 450px;	
		}

	}

	.o-blocksGrid__column{

		display: flex;

		background-color: white;

		flex: 50% 1;

		&--small{
			flex: 40% 0;
		}

		&--smallest{
			flex: 375px 0;
		}

		&--big{
			flex: 60% 0;
		}

	}

}