/*

	Stili per il singolo cocktail

*/

.cocktail{
	display: block;
	align-items: center;

	padding-bottom: 0;
	padding-top: 72px;

  &:after{
    content: '';
    height: 100%;

    position: absolute;
    top: 0;
    bottom: 0;

    left: 50%;
  }
}

	.cocktail-hero{
    padding-bottom: 2rem;
	}

	.cocktail-content{
		margin-bottom: 2rem;
    flex: 40% 0 1;
	}

	.cocktail-content__author{
		margin-bottom: 1rem;
		display: block;
	}

	.cocktail-visual{
		display: flex;

    flex: 60% 1 1;

    max-width: 840px;
		align-items: center;

	}

	.cocktail-visual__cocktailPicture{
		margin: 0 auto;

    width: 50%;

		img{
			width: 100%;
		}

    @media screen and ( min-width: 600px ) {

      transform: translateX(-16.7%);

    }

	}

	.cocktail-visual__guarnizioni{
		padding-right: 2rem;
		display: none;

		margin-left: 0;

		width: 400px;

	}

		.cocktail-visual__guarnizione{
			display: flex;
			align-items: center;
			margin-top: 2rem;

			position: relative;

			&:before, &:after{
				content: '';
				position: absolute;
				top: 50%;

				transform: translateY(-50%);
				background-color: $col-darkBrown;
			}

			&:after{
				width: 73px;
				left: -73px;

				height: 1px;
			}

			&:before{
				width: 6px;
				height: 6px;

				border-radius: 10px;

				left: -85px;
			}
		}

		.cocktail-visual__guarnizioneImg{
			width: 160px;

			img{
				width: 100%;
			}
		}

		.cocktail-visual__guarnizioneTitle{
			font-family: $t-serif;
			font-weight: 600;
			margin-bottom: .5rem;
		}

	.cocktail-data{
		color: white;
		background-color: $col-darkBrown;
		padding: 1rem 0;
	}

		.cocktail-data__wrapper{
			max-width: 1680px;
			margin: 0 auto;
			width: 90%;
		}

		.cocktail-data__content{
			margin-top: 2rem;
		}

		.cocktail-data__title{
			font-size: _fs(24);
			font-family: $t-serif;
		}

@media screen and ( min-width: 600px ){

	.cocktail-visual__cocktailPicture{
		margin: 0;
	}

	.cocktail-visual__guarnizioni{
		display: block;
	}
}

@media screen and ( min-width: 960px ){

	.cocktail-wrapper{
		display: flex;
		margin: 0 auto;
	}

	.cocktail-hero{
		display: flex;
		width: 100%;
		align-items: center;
		max-width: 1680px;
		margin: 0 auto;

		flex-direction: row-reverse;
		justify-content: center;
	}

	.cocktail-content{
		max-width: 580px;
		margin-bottom: 0;
	}

	.cocktail-data__wrapper{
		display: flex;
		justify-content: space-between;

		align-items: top;
	}

}
