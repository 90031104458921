/**
 * Mixin specifici per Product, non condivisi nel resto del sito
 */
@mixin productImage(){

	position: relative;

	display: block;
	width: 100%;

	height: 450px;

	
	transform: rotate3d(360deg);

	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	@media screen and ( min-width: 820px ){

		width:100%;
		display:block;
		margin: 0 auto;
		margin-bottom: 2rem;
		height: auto;
		padding-top: 100vh;

	}

	@media screen and ( min-width: 1025px ){
		// background-size: 100% auto;
		// background-attachment: fixed;

		// background-size: cover;
	}

}