footer {

	padding-bottom: 35px;

	.footer__logo {
		
		display: block;
		margin: 60px auto 50px;
		max-width: 53px;

	}

	p {
		font-size: 10px;
		line-height: 1.6667em;
		font-weight: 500;
		text-align: center;
		text-transform: uppercase;
	}

	.o-socials {
	
		margin: 45px auto 25px;

	}

	@include respondTo("tb") {
		


	}

}

.text-privacy {
	
	margin-top: 0.5rem;

}


.footer__subtext {
	
	color: #B2B2B2;
	margin-top: 30px;

}

	.pointed__line {
		
		display:block;
		padding: 0px;
		position: relative;

		@include respondTo("tb") {
			
			display: inline-block;
			padding: 0 20px;

			&:after {

				content: "•";
				line-height: 1;
				position: absolute;
				right: 6px;
				top: 45%;
				transform: translateY(-50%);
				color: $col-lightBrown;

			}

		}

	}

.form__newsletter {

	max-width: 310px;
	margin: 0 auto 60px;

	input {
		
		font-size: 0.9em;
		text-align: center;

	}

}

.footer__altagamma {
	
	display: block;
    margin: 40px auto 30px;
    width: 100%;
    max-width: 130px;

    img {
		
		display: block;
		width: 100%;
		height: auto;

    }

}

.o-footerMenu {
	
	display: block;
	width: 100%;
	max-width: 630px;
	margin: 0 auto;

	@include respondTo("tb") {
		
		display: flex;

	}

	.o-menu__listItem {
		
		width: 100%;
		display: block; 

		@include respondTo("tb") {
			
			vertical-align: top;
			width: auto;
			flex: (100% / 3);

		}

	}

	.o-menu__item  {
		
		display: block;
		margin: 0px;
		padding: 6px 0;

		@include respondTo("tb") {

			display: inline-block;
			margin: 0 15px;

		}

		.o-menu__singleItem {

			font-size: 12px;
			font-weight: 500;

		}

	}

}