/**
 * @todo: splittare in diversi file
 */

.metodo-hero{
	position: relative;
	width: 100%;
	overflow: hidden;

	background-color: $col-darkBrown;

	min-height: 500px;
	height: calc(100vh - 70px);
	display: flex;

	align-items: center;
	justify-content: center;

	background-image: url('/images/metodo/metodo-mobile.jpg');
	background-position: center;
	background-size: cover;

	@media screen and ( min-width: 1025px ){
		background-image: none;
		height: 100vh;
	}	
}

	.metodo-hero__block{

		text-align: center;

		width: 90%;
		max-width: 532px;

		position: absolute;
		z-index: 5;

		top: calc(70px + 2rem);
		left: 50%;

		transform: translateX(-50%);

		color: white;

		@include respondTo("tb"){
			
			top: 50%;
			transform: translateX(-50%) translateY(-50%);

		}
	}

		.metodo-hero__blockContent{
			font-size: _fs(20);
			line-height: 1.5em;
		}

		.metodo-hero__cta{
			margin-top: 1rem;
		}

	.metodo-hero__video{

		position: absolute;

		margin: 0 auto;

		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		display: none;

		video{
			width: 100%;
			position: absolute;

			top: 50%;

			transform: translateY(-50%);
		}

	}

.metodo-hero__points{
	position: absolute;
	z-index: 3;

	left: 0;
	right: 0;
	bottom: 0;
	top: 0;

}

	.metodo-hero__pointsGroup{
		position: absolute;
		z-index: 4;

		left: 0;
		right: 0;
		bottom: 0;
		top: 0;

		opacity: 0;

		transition: all .3s ease;

		&.is-active{
			opacity: 1;
			transition-delay: .3s;

			z-index: 10;
		}
	}

		.metodo-hero__point{
			position: absolute;
			z-index: 5;

			width: 40px;
			height: 40px;

			background-color: white;

			border-radius: 40px;

			display: flex;

			justify-content: center;
			align-items: center;

			font-size: 2rem;

			color: $col-darkBrown;

			cursor: pointer;
			transition: all .3s ease;

			&:hover{
				transform: scale(1.2);
			}
		}

.metodo-hero__controlBar{
	
	position: absolute;
	width: 100%;
	height: 52px;

	display: flex;

	z-index: 100;
	
	bottom: 0;
	left: 0;
	right: 0;

	display: none;

}

	.metodo-hero__controls{
		display: flex;
		flex: 100px 0 0;

		background-color: white;

		justify-content: center;
		align-items: center;
	}

	.metodo-hero__timeline{
		display: flex;
		flex: 100% 1 1;
		position: relative;

		background-color: rgba( $col-darkBrown, .4 );
	}

	.metodo-hero__timelineSections{

		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 3;

		display: flex;

	}

		.metodo-hero__timelineSection{
			
			flex: 20% 1 1;

			height: 104px;

			display: flex;
			justify-content: center;
			align-items: center;
			
			color: white;
			text-transform: uppercase;
			font-size: _fs(12);

			position: relative;

			cursor: pointer;

			transition: background-color .3s ease;
			cursor: pointer;

			&.is-active{
			}

			&:last-child{
				&:after,
				&:before{
					content: none;
				}
			}

			&:after{
				content: '';
				height: 52px;
				border-right: 1px solid rgba( white, .2 );
				position: absolute;
				right: 0;
				bottom: 0;
			}

			&:hover{
			
				.metodo-hero__timelineSectionBtn{
					background-color: $col-lightBrown;
				}
			}

			&.is-active{

				.metodo-hero__timelineSectionCta{
					opacity: 1;

					&:after{
						height: 14px;
					}
				}

			}

		}

			.metodo-hero__timelineSectionBtn{
				height: 52px;
				position: absolute;

				left: 0;
				bottom: 0;
				right: 0;

				display: flex;
				align-items: center;
				justify-content: center;

				transition: background-color .3s ease;
			}

			.metodo-hero__timelineSectionCta{
				position: absolute;
				top: 0;
				opacity: 0;

				height: 52px;
				
				display: flex;
				align-items: center;
				justify-content: center;

				transition: all .3s ease;

				&:after{
					content: '';
					display: block;
					width: 1px;

					height: 0;
					background-color: white;

					position: absolute;
					bottom: 0;
					transition: all .3s ease;

					left: 50%;
				}
			}

		.metodo-hero__timelineProgressBar{

			background-color: $col-lightBrown;
			position: absolute;
			z-index: 1;
			top: 0;
			bottom: 90%;
			left: 0;
			right: 0;

		}

.metodo-slider{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	background-color: white;

	z-index: 140;

	display: none;
}

	.metodo-heroSlide{
		height: 100%;
		width: 100%;

		overflow-y: scroll;

		display: block;
	}

		.metodo-slider__navigation{
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);

			z-index: 4;
		}

		.metodo-slider__btn {
			
			background-color: $col-lightBrown;
			position: relative;
			width: 50px;
			height: 50px;
			display: block;
			margin-bottom: 1px;

			cursor: pointer;

			svg {
				
				width: 14px;
				height: 14px;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);

				path {
						
					fill: white;

				}

			}

			&.swiper-button-disabled {
				
				opacity: 0.5;

			}

			&:not(.swiper-button-disabled):hover{

				background-color: white;

				svg {
					
					path {
						
						fill: $col-lightBrown;

					}

				}
			}

		}

		.metodo-heroSlide__viewport{

			width: 100%;
			height: 80vw;

			background-color: $col-darkBrown;

			background-size: cover;
			background-position: center;

		}

		.metodo-heroSlide__viewportSlide{
			height: 100%;
			width: 100%;
			background-size: cover;
    		background-repeat: no-repeat;
    		background-position: center;
		}

		.metodo-heroSlide__text{
			padding: 2rem 100px;
			padding-top: 100px;

			font-size: _fs(20);

			-webkit-overflow-scrolling: touch;

			transform: translate3d(0, 0, 0);

			text-align: left;

			line-height: 1.5em;

	    	padding: 1rem 50px;
	    	padding-left: 2rem;
	    	padding-right: 60px;
	    	padding-top: 50px;
			background-color: #fff;

		    height: auto;
		    z-index: 130;

			p{
				margin-bottom: 2rem;
			}

			h3{
				font-weight: 600;
				font-size: 1em;
			}
		}

		.metodo-heroSlide__title{
			text-transform: uppercase;
			margin-bottom: 2rem;

			color: $col-lightBrown;

			font-size: _fs(14);
		}

		.metodo-heroSlide__subtitle{
			font-size: _fs(20);

			line-height: 1.5em;
			font-weight: 600;
			font-family: $t-serif;
		}

		.ui-button--mobile_metodo_cta {
			z-index: 20;
			display: block;
			height: 70px;

			.ui-button__inner {
				
				line-height: 70px;

			}
		}



@media screen and ( min-width: 1025px ){

	.metodo-heroSlide{
		display: flex;
		overflow: hidden;
	}

		.metodo-hero__cta{
			display: none;
		}

		.metodo-hero__video{
			display: block;
		}

		.ui-button--mobile_metodo_cta {
			display: none;
		}

		.metodo-hero__controlBar{
			display: flex;
		}

		.metodo-heroSlide__viewport{
			flex: 50% 0 0;
			height: 100%;
		}

		.metodo-heroSlide__text{
			-webkit-overflow-scrolling: touch;
			overflow-y: scroll;
			padding: 2rem 100px;
			padding-top: 6rem;
		}

}


@media screen and ( max-width: 450px ){
	
	.metodo-hero__timelineSections{
		flex-wrap: wrap;
		
		.metodo-hero__timelineSection{
			flex: 50% 0 0;
			height: 52px;
			border-top:  1px solid rgba(255, 255, 255, 0.2);
		}
	}

	.metodo-hero__timelineProgressBar{
		bottom: 200%;
	}

	.metodo-hero__timelineSection.is-active{
		background-color: $col-lightBrown;

		.metodo-hero__timelineSectionBtn{
			display: none;
		}

		.metodo-hero__timelineSectionCta:after{
			display: none;
		}
	}

}


$brown_light: #956c55;
$brown: #302320;
$brown_dark: #150a0a;


.metodo-gallery{
	background-color: $brown_dark;
	height: 100vh;
}

.metodo-logo{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	width: 100%;
}

.slide{
	height: 100vh;
	overflow: hidden;

	.slide-inner{
		position: relative;
		height: 100%;
		margin: 0 2rem;

	}

	svg {
		max-height: 900px;
	}


	/*** SLIDE 2 in (poi escluso 1) **/
	&:not(.slide-0) .svg-embed svg{
		display: block;
		height: 100%;
		width: 200%;
	    transform: translate(-10%,-21%);

		path{
	    	fill:none;
			
		}
	}
	&__text {
		color: #fff;
	}
}


.svg-embed{
	max-width: 100%;
}


.owl-dot.active{
	background-color: $brown_light;
}



.slide__title{
	text-transform: uppercase;
	color: $brown_light;
	margin-bottom: 1rem;
}



.slide__num{
	position: absolute;
	top: 0;
	left: 0;
	font-size: 20rem;
	font-family: "Playfair Display";
	transform: translate(0,-50%);
	z-index: -1;
	color: $brown;
}


.slide__content{
	position: absolute;
	top: 55%;
	left: 0;
	font-size: 1rem;
	line-height: 1.5;
	text-align: left;
}


.slide-0{
	color: $brown_light;
	text-align: center;

	.title__serif{
		
		font-size: 2rem;
		margin-top: 1rem;
		color: $brown_light;
	}

	path{
		fill: $brown_light;
	}
}







.owl-dots {
	display: none;
}

.slide-4{
	.slide__num{
		position: absolute;
		left: initial;
		right: 5%;
	}

}


.slide-5{
	.slide__num{
		display: none;
	}
}

.slide__image {
	margin: 0;
	position: relative;
	z-index: -1;

	img{
		width: auto !important;
		height: auto;
		display: block;
		max-height: 340px;
		opacity: .3;
		transform: opacity .3s ease-out;
	}

	.active.center & img{
		 opacity: 1; 
	}
}




	.slide__text{
		br{
			display: none;
		}
	}


@media screen and ( min-width: 600px ){


	.metodo-gallery{
		height: 900px;
	}




	.slide{
		height: 900px;
		overflow: visible;

		.slide-inner{
			margin: 0 auto;
		}

		&:not(.slide-0) .svg-embed svg{

			width: 100%;
		    transform: none;
		}
	}
		.slide__text{
		br{
			display: inline;
		}
	}


	.slide-0{
		width: 768px;
	}

	.metodo-logo{
		width: auto;

	}

	.slide__content{
		top: 70%;
		font-size: 1.2rem;
		line-height: 1.7;
		width: 100%;
	}

	.slide__image {
		img{
			max-height: 670px;
		}
	}

	.table{
		width: 60%;
		display: flex;
		margin: 0 auto;

		.col:first-child{
			margin-right: 2rem;
		}
	}
}


.owl-next,
.owl-prev{
	position: absolute;
	top: 50%;
	transform: translateY( -50% );
	border: 0 none;
	width: 3rem;
	height: 3rem;

	span{
		display: none;
	}
	background: url(/images/icons/arrow--left__white.svg) #A67556 no-repeat center;
	background-size: auto 70%;

	padding: 1rem; 
	&.disabled{
		cursor: default;

	}
}

.owl-next{
	transform: translateY( -50% ) rotate( -180deg );
	right: 0;
}

///New section carousel

.container_section_custom{
	height: 551px;
	background: $brown;
	@media screen and (max-width: 1024px) {
		height: auto;
	}
	.swiper-pagination{
		height: 30px;
		bottom:65px;
		width: 50%;
		position:relative;
		z-index: 100;
		@media screen and (max-width: 1024px) {
			bottom:48px;
		}
	}
	.slider-navigation-wrapper{
		position: absolute;
	    left: 0px;
	    top: 50%;
	    transform: translateY(-50%);
	    width: 100%;
	    height: 50px;
	    @media screen and (max-width: 1024px) {
	    	display: none;
	    }
	    .slider-navigation-button{
	    	padding-bottom: 50px;
	    	width: 50px;
	    }
	    .swiper-button-disabled{
	    	visibility: hidden;
	    }
	    .slider-button-next{
	    	right: 0px;
		    position: absolute;
		    margin-top: -50px;
	    }
	}
	.container_img{
		background: $brown;
	    height: 100%;
	    width: 40%;
	    float: left;
	    background-size: cover;
    	background-repeat: no-repeat;
    	background-position: center center;
    	@media screen and (max-width: 1024px) {
    		min-height: 500px;
    		float: none;
    		width: 100%;
    	}
    	@media screen and (max-width: 520px) {
    		min-height: 310px;
    		float: none;
    		width: 100%;
    	}
	}
	.container_info{
		height: 100%;
	    width: 60%;
	    float: right;
	    padding-top: 56px;
	    padding-left: 120px;
	     @media screen and (max-width: 1024px) {
	     	height: 100%;
		    width: calc(100% - 36px);
		    margin: auto;
		    float: none;
		    padding-top: 50px;
		    padding-left: 0px;
	     }
	    .intro_article{
	    	text-align: left;
	    	font-family: "Playfair Display", serif;
	    	color: white;
	    	h1{
			    font-size: 40px;
			    font-weight: bold;
			    letter-spacing: 0.8px;
			    margin-left: 0px;
			    @media screen and (max-width: 1024px) {
			    	font-size: 30px;
			    	letter-spacing: 0.6px;
			    }
	    	}
	    	span{
	    		font-size: 100px;
			    font-weight: bold;
			    color: rgba(166, 117, 86, 0.1);
			    line-height: 0.88;
			    letter-spacing: 2px;
			    position: relative;
			    top: -46px;
			    margin-bottom: 78px;
			    margin-left: -40px;
			    @media screen and (max-width: 1024px) {
			    	font-size: 50px;
			    	letter-spacing: 1px;
  					color: rgba(166, 117, 86, 0.05);
  					top: -34px;
  					margin-left: -15px;
			    }
	    	}
	    }
	    .content_article{
	    	text-align: left;
	    	width: calc(100% - 141px);
	    	color: white;
	    	margin-top: 32px;
	    	@media screen and (max-width: 1024px) {
	    		margin-top: 0px;
	    		width: 100%;
	    	}
	    	span{
	    		border-bottom:3px solid $brown_light;
	    		font-family: "Playfair Display", serif;
	    		font-size: 30px;
  				font-weight: bold;
  				letter-spacing: 0.6px;
  				display: inline-block;
  				width: auto;
  				float: left;
  				padding-bottom:26px;
  				margin-right: 10%;
  				margin-bottom: 264px;
  				@media screen and (max-width: 1024px) {
  					font-size: 24px;
  					padding-bottom:18px;
  					margin-bottom: 20px;
  					float: none;
  				}
	    	}
	    	p{
	    		font-size: 20px;
			    line-height: 1.5;
			    letter-spacing: 0.4px;
			    display: block;
			    max-width: 642px;
			    min-width: 400px;
			    margin: auto;
			    @media screen and (max-width: 1024px) {
			    	min-width: auto;
			    	max-width: auto;
			    	padding-bottom:60px;
			    	font-size: 16px;
			    	letter-spacing: 0.32px;
			    	margin: 0;
			    }
	    	}
	    }
	}
}


.container_section_custom_sec{
	height: 550px;
	background-size: cover;
	background-repeat: no-repeat;
	position:relative;
	//background-attachment: fixed;
	@media screen and (max-width: 768px) {
		height: auto;
	}
	.container_section_inner{
		height: auto;
	    position: absolute;
	    width: 100%;
	    max-width: 507px;
	    top: 50%;
	    left: 158px;
	    transform: translate(0, -50%);
	    color: white;
	    @media screen and (max-width: 768px) {
	    	height: auto;
		    position: relative;
		    width: calc(100% - 36px);
		    top: 0;
		    left: 0;
		    transform: none;
		    color: white;
		    margin: auto;
	    }
	    h1{
	      font-family: "Playfair Display", serif;
		  font-size: 40px;
		  font-weight: bold;
		  letter-spacing: 0.8px;
		  margin-bottom:36px;
		  max-width: 507px;
		  display: block;
		  text-align: center;
		  @media screen and (max-width: 768px) {
		  	font-family: "Playfair Display", serif;
		    font-size: 30px;
		    font-weight: bold;
		    letter-spacing: 0.8px;
		    margin-bottom: 20px;
		    max-width: 507px;
		    display: block;
		    text-align: center;
		    padding-top: 40px;
		  }
	    }
	    p{
		  font-size: 20px;
		  letter-spacing: 0.28px;
		  line-height: 1.5;
		  display: block;
		  max-width: 507px;
		  text-align: center;
		  margin-top: 16px;
		  @media screen and (max-width: 768px) {
		  	padding-bottom:40px;
		  }
	    }
	}
}

.custom_add_as{
	font-weight: bold;
    font-family: "Playfair Display",serif;
    margin-top: -10px;
}
