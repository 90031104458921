.storia-wrapper{
	
	// background-color: $col-darkBrown;
	// color: white;

}

.storia-viewport{
	display: none;
}

.storia-hero{
	
	padding: 2rem 1rem;

	text-align: center;
	width: 100%;
	margin: 0 auto;

	color: $col-dark;
	display: flex;

	align-content: center;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	background-color: white;

	@include respondTo("tb") {
		
		min-height: 400px;
		max-height: 400px;
		height: 80vh;
		padding: 5rem 1rem;

	}
}
	
	.storia-hero__title{
		margin-bottom: 2rem;
	}

@import 'year';
@import 'decade';
@import 'episode';
@import 'navigation';

@import 'desktop';