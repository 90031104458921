.ageCheck{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;

	// background-color: $col-lightBrown;
	// color: white;

	background-color: $col-darkBrown;

	text-align: center;

	z-index: 399;

	display: flex;
	
	align-items: center;

	padding-top: 50px;

	@include respondTo("tb") {
		
		padding-top: 0px;
		justify-content: center;

	}
	

	flex-direction: column;

}

	.ageCheck__wrapper{
		
		width: 100%;
		max-width: 500px;
		justify-self: center;

	}

	.ageCheck__logo {
		
		display: block;
		width: 100%;
		max-width: 250px;
		margin: 0 auto;

		svg {
			
			display: block;

		}

	}

	.ageCheck__title{
		color: white;
		font-size: _fs(25);
		font-family: $t-serif;
		font-weight: 600;
		line-height: 1.26em;

		margin: 43px 0;
	}

	.ageCheck__inputs{
		display: flex;
		justify-content: center;

		> .ui-button {
			
			min-width: auto;
		    width: 100%;
		    height: 70px;
		    max-width: 115px;
		    margin-right: 0.5rem;
		    margin-bottom: 0px;

		    font-weight: 500;
	    	letter-spacing: 1px;

	    	font-size: _fs(15);

			.ui-button__inner {
				
				padding: 0px;
				line-height: 70px;

			}


			&:last-child {

				margin-left: 0.5rem;
				margin-right: 0;
			}

			
		}


		.ui-button.is-focus {
		
			background: transparent;
			
			input {
				
				color: white;

			}

		}

	}

	.ageCheck__errors{
		display: none;
		margin-bottom: 1rem;

		color: #d83d3d;
	}

	.ageCheck__remember{
		font-size: 14px;
		text-transform: uppercase;
		color: #b2b2b2;
		display: block;

		text-align: left;
		margin: 30px 0;

		input{
			margin-right: 1rem;
		}
	}

	.ageCheck__disclaimer{
		
		position: static;
		margin-top: 50px;
		color: #b2b2b2;
		width: 100%;
		max-width: 1000px;
		padding: 0 1rem;

		font-size: _fs(14);
		line-height: 1.3em;

		@include respondTo("tb") {
			
			padding: 0px;
			margin-top: 0px;
			position: absolute;
			bottom: 40px;
			left: 50%;
			transform: translateX(-50%);

		}
	}